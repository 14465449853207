import { IQuiz } from "../models/IQuestions";
export const QuizByTeam: IQuiz[] = [
  {
    id: 0,
    team: "AIK",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "AIK:s fotbollssektion för damer bildades 1970.  På AIK:s klubbemblem står dock ett annat årtal, nämligen då själva föreningen grundades. Vilket är årtalet?",
        answers: [
          { answer: "1890", isCorrect: !1 },
          { answer: "1891", isCorrect: !0 },
          { answer: "1893", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "På hemmaplan för AIK:s damlag får ungefär 4 000 besökare plats. Vad heter idrottsplatsen?",
        answers: [
          { answer: "Råsunda IP", isCorrect: !1 },
          { answer: "Skytteholms IP", isCorrect: !0 },
          { answer: "Bergshamra IP", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "Vilken är den dominerande färgen i bortastället för AIK:s damer?",
        answers: [
          { answer: "Vitt", isCorrect: !0 },
          { answer: "Gult", isCorrect: !1 },
          { answer: "Svart", isCorrect: !1 },
        ],
      },
      {
        id: 3,
        question: "2020 vann AIK Elitettan och slog poängrekord med:",
        answers: [
          { answer: "75 poäng", isCorrect: !1 },
          { answer: "73 poäng", isCorrect: !1 },
          { answer: "72 poäng", isCorrect: !0 },
        ],
      },
      {
        id: 4,
        question:
          "2020 blev Adelisa Grabus den första AIK-spelaren att vinna skytteligan i Elitettan. Hur många mål gjorde hon under säsongen?",
        answers: [
          { answer: "27 mål", isCorrect: !1 },
          { answer: "25 mål", isCorrect: !0 },
          { answer: "22 mål", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Mittbacken Jennie Nordin är tillbaka i AIK för sin tredje sejour i klubben. Vilket lag spelade hon för säsongen 2021?",
        answers: [
          { answer: "Linköpings FC", isCorrect: !1 },
          { answer: "Växjö DFF", isCorrect: !1 },
          { answer: "Piteå IF DFF", isCorrect: !0 },
        ],
      },
      {
        id: 6,
        question:
          "Inför säsongen 2022 lämnade Rosa Kafaji AIK för BK Häcken. Vad heter klubben där hon debuterade som åttaåring?",
        answers: [
          { answer: "Kallhälls FF", isCorrect: !0 },
          { answer: "Bele Barkarby FF", isCorrect: !1 },
          { answer: "Kista SC", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question: "Vilket år vann AIK OBOS Damallsvenskan?",
        answers: [
          { answer: "1995", isCorrect: !1 },
          { answer: "1998", isCorrect: !1 },
          {
            answer: "AIK har aldrig vunnit OBOS Damallsvenskan",
            isCorrect: !0,
          },
        ],
      },
      {
        id: 8,
        question:
          "Vad heter återvändande forwarden från Eskilstuna United DFF?",
        answers: [
          { answer: "Sara Nordin", isCorrect: !1 },
          { answer: "Jenny Danielsson", isCorrect: !1 },
          { answer: "Emma Engström", isCorrect: !0 },
        ],
      },
      {
        id: 9,
        question:
          "Under 2022 kom beskedet att mittfältaren Johanna Lindell lämnar AIK. Varför?",
        answers: [
          { answer: "På grund av studier", isCorrect: !0 },
          { answer: "På grund av skador", isCorrect: !1 },
          { answer: "På grund av byte till annan klubb", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Black Ladies är en supportförening för AIK:s olika damavdelningar. Vilket år grundades föreningen?",
        answers: [
          { answer: "2015", isCorrect: !1 },
          { answer: "2013", isCorrect: !0 },
          { answer: "2010", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "Nyförvärvet Agnes Nyberg är mittfältare i AIK Fotboll. Vilket tröjnummer har hon?",
        answers: [
          { answer: "Nr 6", isCorrect: !0 },
          { answer: "Nr 12", isCorrect: !1 },
          { answer: "Nr 22", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question: "AIK:s klubbmärke innehåller tre symboler, vars innebörd är:",
        answers: [
          {
            answer: "Styrka (tornen), tradition (balken) och framgång (solen)",
            isCorrect: !1,
          },
          {
            answer: "Tradition (tornen), styrka (balken) och framgång (solen)",
            isCorrect: !0,
          },
          {
            answer: "Framgång (tornen), styrka (balken) och tradition (solen)",
            isCorrect: !1,
          },
        ],
      },
      {
        id: 13,
        question:
          "Slutplaceringen i OBOS Damallsvenskan 2008 är AIK:s bästa hittills, med:",
        answers: [
          { answer: "En tredjeplacering", isCorrect: !1 },
          { answer: "En fjärdeplacering", isCorrect: !0 },
          { answer: "Vinst av OBOS Damallsvenskan", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "När spelade klubben i OBOS Damallsvenskan för första gången?",
        answers: [
          { answer: "1989", isCorrect: !1 },
          { answer: "1991", isCorrect: !1 },
          { answer: "1993", isCorrect: !0 },
        ],
      },
      {
        id: 15,
        question:
          "Den 10 oktober 2021 slogs nytt publikrekord i OBOS Damallsvenskan under matchen mellan AIK och Hammarby IF. Hur många personer satt i publiken?",
        answers: [
          { answer: "18 325 åskådare", isCorrect: !1 },
          { answer: "18 537 åskådare", isCorrect: !0 },
          { answer: "18 789 åskådare", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "Vad är det för underlag på Skytteholms IP där laget ofta spelar?",
        answers: [
          { answer: "Konstgräs", isCorrect: !0 },
          { answer: "Naturgräs", isCorrect: !1 },
          { answer: "Hybridgräs", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Vilken placering fick laget i OBOS Damallsvenskan 2021 med totalt 20 poäng?",
        answers: [
          { answer: "En niondeplats", isCorrect: !1 },
          { answer: "En tiondeplats", isCorrect: !0 },
          { answer: "En elfteplats", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "AIK-tränarna Jesper Björk, Nebojša Novaković och Michael Nilsson arbetar sedan tidigare inom:",
        answers: [
          { answer: "IK Uppsala", isCorrect: !1 },
          { answer: "Jitex BK", isCorrect: !1 },
          { answer: "AIK Fotboll", isCorrect: !0 },
        ],
      },
      {
        id: 19,
        question: "Vad kostar AIK:s klassiska halsduk med brodyr i webbshopen?",
        answers: [
          { answer: "199 kr", isCorrect: !0 },
          { answer: "249 kr", isCorrect: !1 },
          { answer: "299 kr", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "Hur många säsonger har AIK spelat i OBOS Damallsvenskan, 2022 inräknat?",
        answers: [
          { answer: "10 säsonger", isCorrect: !1 },
          { answer: "12 säsonger", isCorrect: !1 },
          { answer: "13 säsonger", isCorrect: !0 },
        ],
      },
      {
        id: 21,
        question:
          "Stämmer det att tränarlegendaren Pia Sundhage en gång i tiden var assisterande tränare för AIK:s damer?",
        answers: [
          { answer: "Ja, år 2000", isCorrect: !0 },
          { answer: "Ja, år 2001", isCorrect: !1 },
          {
            answer: "Nej, Pia Sundhage har aldrig tränat AIK:s damer",
            isCorrect: !1,
          },
        ],
      },
      {
        id: 22,
        question:
          "2007 går AIK hela vägen till final i Svenska Cupen, men förlorar med 4-3. Vilket är laget som besegrar AIK?",
        answers: [
          { answer: "LdB FC Malmö, numera FC Rosengård", isCorrect: !1 },
          { answer: "Umeå IK", isCorrect: !0 },
          { answer: "KIF Örebro DFF", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "2021 blev en tung säsong för AIK i OBOS Damallsvenskan, som slutade på en tiondeplats med:",
        answers: [
          { answer: "16 poäng", isCorrect: !1 },
          { answer: "20 poäng", isCorrect: !0 },
          { answer: "21 poäng", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Forwarden Remy Siemsen har kommit hela vägen från Australien för att spela fotboll i AIK. Vilken var hennes senaste klubb?",
        answers: [
          { answer: "Perth Glory", isCorrect: !1 },
          { answer: "Melbourne City", isCorrect: !1 },
          { answer: "Sydney FC", isCorrect: !0 },
        ],
      },
      {
        id: 25,
        question:
          "AIK:s målvakt Milla-Maj Majasaari har uppmärksammats för sin fantastiska debutsäsong 2020, då hon noterades för:",
        answers: [
          { answer: "11 nollor", isCorrect: !1 },
          { answer: "12 nollor", isCorrect: !1 },
          { answer: "13 nollor", isCorrect: !0 },
        ],
      },
      {
        id: 26,
        question:
          "Den 10 maj 1970 spelade AIK:s damsektion sin första organiserade match. Vilket var motståndarlaget?",
        answers: [
          { answer: "Öxabäck IF", isCorrect: !1 },
          { answer: "Jakobsbergs GoIF", isCorrect: !1 },
          { answer: "Ronna", isCorrect: !0 },
        ],
      },
      {
        id: 27,
        question:
          "Vilken spelare i AIK:s trupp har tidigare spelat för Jitex BK i Elitettan?",
        answers: [
          { answer: "Nora Rönnfors", isCorrect: !0 },
          { answer: "Ebba Jahnfors", isCorrect: !1 },
          { answer: "Agnes Nyberg", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "Under 2022 kommer totalt fyra spelare ur AIK:s damtrupp att lånas ut. En av dem är försvararen Julia Laukström, som lånas ut till:",
        answers: [
          { answer: "Sollentuna FK", isCorrect: !1 },
          { answer: "Gamla Upsala SK", isCorrect: !0 },
          { answer: "Bollstanäs SK", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Mittbacken Linda Sembrant spelade för AIK 2008-2010 och är i dag försvarare i italienska Juventus. Som barn ville hon dock bli stjärna i en annan sport. Vilken?",
        answers: [
          { answer: "Konståkning", isCorrect: !0 },
          { answer: "Ridning", isCorrect: !1 },
          { answer: "Hockey", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 1,
    team: "BP",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "2022 flyttades BP upp till OBOS Damallsvenskan för första gången. Hur många poäng fick laget i Elitettan säsongen 2021?",
        answers: [
          { answer: "48 poäng", isCorrect: !1 },
          { answer: "49 poäng", isCorrect: !0 },
          { answer: "50 poäng", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "Hösten 2021 utsågs anfallaren Mathilda Johansson Prakt till Månadens spelare i Elitettan, för månaden:",
        answers: [
          { answer: "September", isCorrect: !0 },
          { answer: "Oktober", isCorrect: !1 },
          { answer: "November", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "Vilken spelare i BP drog på sig flest gula kort (3 stycken) under föregående säsong i Elitettan?",
        answers: [
          { answer: "Frida Boriero", isCorrect: !1 },
          { answer: "Jennifer Sjösten", isCorrect: !1 },
          { answer: "Klara Andrup", isCorrect: !0 },
        ],
      },
      {
        id: 3,
        question:
          "Pia Carlsson var bara 13 år när hon debuterade i damlaget 1979. Hon är den första damspelaren att väljas in i BP:s Hall of Fame. Vilket år blev hon invald?",
        answers: [
          { answer: "2017", isCorrect: !0 },
          { answer: "2018", isCorrect: !1 },
          { answer: "2019", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "Anfallaren Pernilla Hederoth var aktiv mellan åren 1989-1999 och har spelat flest matcher av alla spelare i damlaget någonsin med sina:",
        answers: [
          { answer: "203 matcher", isCorrect: !0 },
          { answer: "205 matcher", isCorrect: !1 },
          { answer: "210 matcher", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "BP är den största fotbollsföreningen i hela Europa sett till antalet spelare. Hur många aktiva spelare har klubben?",
        answers: [
          { answer: "3 000 spelare", isCorrect: !1 },
          { answer: "4 000 spelare", isCorrect: !0 },
          { answer: "5 000 spelare", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "BP säkrade avancemanget till OBOS Damallsvenskan i Elitettans sista omgång med en avgörande vinst. Vilket var det förlorande laget?",
        answers: [
          { answer: "Jitex BK", isCorrect: !1 },
          { answer: "IK Uppsala", isCorrect: !1 },
          { answer: "Älvsjö AIK", isCorrect: !0 },
        ],
      },
      {
        id: 7,
        question:
          "Stadium är en av BP:s huvudpartners. Var sitter företagets logotyp på matchtröjan?",
        answers: [
          { answer: "På högra axeln", isCorrect: !1 },
          { answer: "På vänstra axeln", isCorrect: !0 },
          { answer: "Över bröstet", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "Lagerkransen på BP:s klubbmärke är en symbol för seger och härstammar från antikens dagar. Vilken grekisk gud avbildas ofta med lagerkransen?",
        answers: [
          { answer: "Apollon, ljusets och konsternas gud", isCorrect: !0 },
          { answer: "Athena, vishetens och krigets gudinna", isCorrect: !1 },
          {
            answer: "Hermes, köpmännens och idrottsmännens gud",
            isCorrect: !1,
          },
        ],
      },
      {
        id: 9,
        question: "Vilket år bildades damsektionen av IF Brommapojkarna?",
        answers: [
          { answer: "1970", isCorrect: !1 },
          { answer: "1971", isCorrect: !0 },
          { answer: "1972", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Hur stor del av verksamheten utgör IF Brommapojkarnas dam- och flickverksamhet?",
        answers: [
          { answer: "En tredjedel", isCorrect: !1 },
          { answer: "En fjärdedel", isCorrect: !0 },
          { answer: "En femtedel", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "Renoveringen av BP:s hemmaplan Grimsta IP har blivit mycket dyrare än beräknat – och måste ändå göras om på grund av sprickor och andra skador. Vad har bygget kostat hittills?",
        answers: [
          { answer: "79 miljoner kronor", isCorrect: !1 },
          { answer: "83 miljoner kronor", isCorrect: !1 },
          { answer: "87 miljoner kronor", isCorrect: !0 },
        ],
      },
      {
        id: 12,
        question:
          "Våren 2022 förstärkte BP sin defensiv med den rutinerade försvararen Vaila Barsley. Var är hon född?",
        answers: [
          { answer: "England", isCorrect: !0 },
          { answer: "Shetlandsöarna", isCorrect: !1 },
          { answer: "Wales", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Mittfältaren Alice Ahlberg är ny mittfältare i BP efter två säsonger i Älvsjö AIK. Vilken är hennes moderklubb?",
        answers: [
          { answer: "Enskede IK", isCorrect: !1 },
          { answer: "Tyresö FF", isCorrect: !1 },
          { answer: "Boo FF", isCorrect: !0 },
        ],
      },
      {
        id: 14,
        question:
          "Michelle Rojas Flores är en av spelarna som lämnat BP inför säsongen 2022. Hur många tävlingsmatcher har hon totalt spelat för laget?",
        answers: [
          { answer: "124 matcher", isCorrect: !0 },
          { answer: "135 matcher", isCorrect: !1 },
          { answer: "140 matcher", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question: "Vad heter huvudtränaren för BP?",
        answers: [
          { answer: "Daniel Gunnars", isCorrect: !1 },
          { answer: "Marcelo Fernandez", isCorrect: !0 },
          { answer: "Antonio Fernandez", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "I BP:s Hall of Fame hyllas lagledare och spelare som med sin utmärkande insats varit mycket betydelsefulla för BP:s klubbliv. Hur många kvinnor är invalda?",
        answers: [
          { answer: "En kvinna; Pia Carlsson", isCorrect: !1 },
          {
            answer: "Två kvinnor; Pia Carlsson och Pernilla Hederoth",
            isCorrect: !1,
          },
          {
            answer:
              "Tre kvinnor; Pia Carlsson, Pernilla Hederoth och Anna Sandberg",
            isCorrect: !0,
          },
        ],
      },
      {
        id: 17,
        question:
          "BP:s officiella supporterförening Bromma Support stöttar såväl herr- som damlagen i klubben. När bildades den?",
        answers: [
          { answer: "2011", isCorrect: !1 },
          { answer: "2012", isCorrect: !0 },
          { answer: "2013", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "BP har en stor webbshop där supportrar kan köpa allt från tröjor till mössor och halsdukar för att stötta sitt lag. Hur lyder texten som är tryckt på många av plaggen?",
        answers: [
          { answer: "Europas främsta fotbollsförening", isCorrect: !1 },
          { answer: "Europas största fotbollsförening", isCorrect: !0 },
          { answer: "Europas äldsta fotbollsförening", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "När BP spelar match igen, hurra hurra, då” – ja, vadå egentligen? Kan du nästan rad på hejarramsan?",
        answers: [
          { answer: "Då dunkar hjärtat rött och svart", isCorrect: !0 },
          { answer: "Då klär vi oss i rött och svart", isCorrect: !1 },
          { answer: "Då sjunger vi för rött och svart", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "Årets Pojke & Flicka är ett pris som delas ut av Bromma Support till den mest framträdande spelare på herr- och damsidan. Vilken tjej fick priset 2021?",
        answers: [
          { answer: "Saga Swedman", isCorrect: !1 },
          { answer: "Alexsandra Lobanova", isCorrect: !1 },
          { answer: "Lovisa Norrby", isCorrect: !0 },
        ],
      },
      {
        id: 21,
        question:
          "När BP avancerade till OBOS Damallsvenskan fick laget en viss summa pengar i förbundsbidrag. Hur stor var summan?",
        answers: [
          { answer: "500 000 kronor", isCorrect: !1 },
          { answer: "1 miljon kronor", isCorrect: !0 },
          { answer: "2 miljoner kronor", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "2022 är BP:s första säsong i OBOS Damallsvenskan. När arrangerades serien för första gången?",
        answers: [
          { answer: "1987", isCorrect: !1 },
          { answer: "1988", isCorrect: !0 },
          { answer: "1989", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "I Elitettan 2021 placerade sig BP på en sjundeplats över lagen som lockade flest supportrar under säsongen. Vad var BP:s publiksnitt hemma under pandemiåret?",
        answers: [
          { answer: "76", isCorrect: !1 },
          { answer: "80", isCorrect: !0 },
          { answer: "109", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Vilken anfallare kommer senast från Hammarby Fotboll, men är nu en del av BP:s trupp? ",
        answers: [
          { answer: "Frida Thörnqvist", isCorrect: !0 },
          { answer: "Jennifer Sjösten", isCorrect: !1 },
          { answer: "Saga Swedman", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "I april 2022 spelade det svenska damlandslaget VM-kvalmatcher mot Georgien och Irland. I truppen ingick en spelare med rötter i BP. Vem?",
        answers: [
          { answer: "Amanda Nildén", isCorrect: !0 },
          { answer: "Hedvig Lindahl", isCorrect: !1 },
          {
            answer:
              "Det stämmer inte. Ingen spelare med rötter i BP ingick i truppen.",
            isCorrect: !1,
          },
        ],
      },
      {
        id: 26,
        question:
          "BP-spelaren Matilda Nildén lämnade klubben 2021 för att i stället skriva på för AIK. Har hon några syskon med rötter i BP?",
        answers: [
          { answer: "Ja, systern Amanda och brodern Charlie", isCorrect: !1 },
          {
            answer: "Ja, systern Amanda och bröderna Simon och Charlie",
            isCorrect: !0,
          },
          { answer: "Nej, hon har inga syskon", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question:
          "I april 2022 kammade BP hem sin första allsvenska trepoängare i OBOS Damallsvenskan. Vilket var motståndarlaget?",
        answers: [
          { answer: "Eskilstuna United", isCorrect: !1 },
          { answer: "Piteå IF", isCorrect: !1 },
          { answer: "Umeå IK", isCorrect: !0 },
        ],
      },
      {
        id: 28,
        question:
          "Med säsongskortet 2022 kan supportrar se samtliga seniormatcher på dam- och herrsidan på Grimsta IP. Vad kostar säsongskortet med biljettalternativet Sittplats Ordinarie?",
        answers: [
          { answer: "1200 kronor", isCorrect: !1 },
          { answer: "1300 kronor", isCorrect: !1 },
          { answer: "1500 kronor", isCorrect: !0 },
        ],
      },
      {
        id: 29,
        question:
          "Vilket lag möter BP i säsongens sista match i OBOS Damallsvenskan?",
        answers: [
          { answer: "FC Rosengård", isCorrect: !0 },
          { answer: "Umeå IK", isCorrect: !1 },
          { answer: "Hammarby IF", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 2,
    team: "Djurgården",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "På grund av en sammanslagning med Älvsjö 2003 är det många som tror att Djurgårdens damlag bildades först då. Detta är helt felaktigt, då Djurgårdens damer varit aktiva sedan:",
        answers: [
          { answer: "1960-talet", isCorrect: !0 },
          { answer: "1970-talet", isCorrect: !1 },
          { answer: "1980-talet", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question: "Hur många gånger har Djurgården vunnit OBOS Damallsvenskan?",
        answers: [
          { answer: "En gång; 2003", isCorrect: !1 },
          { answer: "Två gånger; 2003 och 2004", isCorrect: !0 },
          { answer: "Tre gånger; 2000, 2003 och 2004", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question: "Vem är den nya lagkaptenen för Djurgårdens damer?",
        answers: [
          { answer: "Sanna Kullberg", isCorrect: !0 },
          { answer: "Nellie Lilja", isCorrect: !1 },
          { answer: "Sara Olai", isCorrect: !1 },
        ],
      },
      {
        id: 3,
        question:
          "Efter sex år i DIF valde Sheila van den Bulk att lämna laget. Vilken klubb spelar hon för nu?",
        answers: [
          { answer: "Kristianstads DFF", isCorrect: !0 },
          { answer: "Eskilstuna United", isCorrect: !1 },
          { answer: "BK Häcken", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "Djurgårdens IF grundandes 1891. Vilket år togs Djurgårdens klubbmärke fram, som det ser ut i dag?",
        answers: [
          { answer: "Samtidigt, alltså 1891", isCorrect: !1 },
          { answer: "1893", isCorrect: !1 },
          { answer: "1896", isCorrect: !0 },
        ],
      },
      {
        id: 5,
        question: "Vad symboliserar det röda fältet i Djurgårdsskölden?",
        answers: [
          { answer: "Kärlek", isCorrect: !0 },
          { answer: "Styrka", isCorrect: !1 },
          { answer: "Stolthet", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "Sedan 2016 är Djurgården tillbaka i OBOS Damallsvenskan. Vilket var året då laget åkte ur serien?",
        answers: [
          { answer: "2011", isCorrect: !1 },
          { answer: "2012", isCorrect: !0 },
          { answer: "2013", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Djurgårdsanfallaren Hayley Dowd har tidigare spelat för bland annat Morön BK. Var är hon född?",
        answers: [
          { answer: "USA", isCorrect: !0 },
          { answer: "Kanada", isCorrect: !1 },
          { answer: "England", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "I maj 2022 slog Djurgården Fotboll nytt medlemsrekord med över:",
        answers: [
          { answer: "20 000 medlemmar", isCorrect: !1 },
          { answer: "21 000 medlemmar", isCorrect: !0 },
          { answer: "25 000 medlemmar", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          "Målvakten Elvira Björklund ser sig själv som en kommunikativ spelare med bra spelförståelse och räckvidd. Hur lång är hon?",
        answers: [
          { answer: "180 cm", isCorrect: !1 },
          { answer: "182 cm", isCorrect: !0 },
          { answer: "185 cm", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Vilken DIF-spelare ingick inte i truppen för U23-landslaget 2022?",
        answers: [
          { answer: "Lova Lundin", isCorrect: !0 },
          { answer: "Nellie Lilja", isCorrect: !1 },
          { answer: "StinaLisa Johansson", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "Andreas Isaksson är äntligen tillbaka i blårandigt – den här gången som målvaktstränare för DIF:s damer. Hur många matcher spelade han som målvakt för Djurgården?",
        answers: [
          { answer: "135 matcher", isCorrect: !0 },
          { answer: "145 matcher", isCorrect: !1 },
          { answer: "150 matcher", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Under 2022 lånas DIF-försvararen Ellen Andersson ut för spel i Elitettan. Till vilket lag?",
        answers: [
          { answer: "Mallbackens IF", isCorrect: !0 },
          { answer: "Gamla Upsala SK", isCorrect: !1 },
          { answer: "IK Uppsala", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Det blev ingen semifinal för DIF:s damer i Svenska Cupen 2022. 0-0 räckte inte för avancemang i matchen mot:",
        answers: [
          { answer: "Piteå IF", isCorrect: !1 },
          { answer: "Eskilstuna United", isCorrect: !0 },
          { answer: "Mallbackens IF", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Mittfältaren Linda Motlhalo som bland annat spelat för Sydafrika i det Afrikanska Mästerskapet. Vilket är hennes tröjnummer i DIF-truppen?",
        answers: [
          { answer: "Nummer 9", isCorrect: !1 },
          { answer: "Nummer 10", isCorrect: !1 },
          { answer: "Nummer 11", isCorrect: !0 },
        ],
      },
      {
        id: 15,
        question:
          "DIF stötte på starkt ryskt motstånd under årets första träningsmatch i Turkiet. Vilket var motståndarlaget?",
        answers: [
          { answer: "Zenit Sankt Petersburg", isCorrect: !0 },
          { answer: "Chertanovo Moscow", isCorrect: !1 },
          { answer: "WFC Krasnodar", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "Nyförvärvet Emelie Lövgren spelar just nu sin första säsong i DIF. Vilken var hennes första klubb i OBOS Damallsvenskan?",
        answers: [
          { answer: "IFK Kalmar", isCorrect: !1 },
          { answer: "Umeå IK", isCorrect: !1 },
          { answer: "Piteå IF", isCorrect: !0 },
        ],
      },
      {
        id: 17,
        question:
          "DIF:s Lova Lundin har tidigare spelat för spanska Logroño. Var i Sverige är hon född?",
        answers: [
          { answer: "Borlänge", isCorrect: !0 },
          { answer: "Rättvik", isCorrect: !1 },
          { answer: "Falun", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "Vilken spelare gjorde flest mål för laget under säsongen 2021 i OBOS Damallsvenskan med totalt 5 mål?",
        answers: [
          { answer: "Sara Olai", isCorrect: !0 },
          { answer: "Hayley Dowd", isCorrect: !1 },
          { answer: "Nellie Lilja", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Stockholm Stadion är hemmaplan för Djurgårdens damer. Hur stor är Stadions publikkapacitet för sittande publik vid fotbollsmatcher?",
        answers: [
          { answer: "14 000", isCorrect: !1 },
          { answer: "14 200", isCorrect: !0 },
          { answer: "14 500", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "Vilket vandringspris tilldelas laget som vinner OBOS Damallsvenskan?",
        answers: [
          { answer: "Susanne Erlandssons pokal", isCorrect: !1 },
          { answer: "Kronprinsessan Victorias pokal", isCorrect: !0 },
          { answer: "Gustaf VI Adolfs pokal", isCorrect: !1 },
        ],
      },
      {
        id: 21,
        question:
          "Det blev en niondeplacering för DIF i OBOS Damallsvenskan 2021, med poängen:",
        answers: [
          { answer: "20", isCorrect: !1 },
          { answer: "21", isCorrect: !0 },
          { answer: "22", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "Den nya DIF-anfallaren Sara Lilja Vidlund har över 20 U-landskamper på CV:t och är sedan tidigare mycket nära vän med en annan spelare i truppen. Vem?",
        answers: [
          { answer: "Sanna Kullberg", isCorrect: !1 },
          { answer: "Ebba Wieder", isCorrect: !1 },
          { answer: "Julia Walentowicz", isCorrect: !0 },
        ],
      },
      {
        id: 23,
        question:
          "Djurgårdens IF grundades 1891 av John G. Jansson, klubbens första ordförande. När tilläts kvinnor bli medlemmar i föreningen?",
        answers: [
          { answer: "1900", isCorrect: !1 },
          { answer: "1908", isCorrect: !0 },
          { answer: "1915", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Den forna DIF-spelaren Victoria “Vickan” Sandell Svensson är en av de bästa målskyttarna i svenska landslaget genom tiderna. Den 16 juni 2007 slog hon rekord i flest mål i en landskapsmatch i mötet mot Rumänien med: ",
        answers: [
          { answer: "4 mål", isCorrect: !1 },
          { answer: "5 mål", isCorrect: !0 },
          { answer: "7 mål", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Den forna DIF-spelaren Kristin “Kicki” Bengtsson spelade hela 157 landskamper i blågult under sin karriär. Stämmer det att hon vunnit Diamantbollen?",
        answers: [
          { answer: "Nej", isCorrect: !1 },
          { answer: "Ja, 1994", isCorrect: !1 },
          { answer: "Ja, 1994 och 2004", isCorrect: !0 },
        ],
      },
      {
        id: 26,
        question:
          "Den danska mittbacken Kamilla Karlsen har tröjnummer 6 i DIF:s trupp. Hon har tidigare spelat för den franska klubben:",
        answers: [
          { answer: "FC Fleury 91", isCorrect: !0 },
          { answer: "Paris FC", isCorrect: !1 },
          { answer: "Le Havre", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question:
          "Mittfältaren Nea Flyborg kan skryta med föräldrar som båda vunnit SM-guld för Djurgården. Vilken är både Neas och mamma Elins moderklubb?",
        answers: [
          { answer: "Tyresö FF", isCorrect: !1 },
          { answer: "IFK Lidingö", isCorrect: !0 },
          { answer: "Boo FF", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "År 2003, när DIF hette Djurgården/Älvsjö, kammade Victoria “Vickan” Sandell Svensson hem titeln som OBOS Damallsvenskans skyttedrottning. Hur många mål gjorde hon totalt?",
        answers: [
          { answer: "23 mål", isCorrect: !0 },
          { answer: "32 mål", isCorrect: !1 },
          { answer: "34 mål", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Vilket lag besegrades av dåvarande Djurgården/Älvsjö i Svenska Cupen 2004, då DIF vann guldet?",
        answers: [
          { answer: "Umeå IK", isCorrect: !0 },
          { answer: "Hammarby IF", isCorrect: !1 },
          { answer: "Malmö FF", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 3,
    team: "Eskilstuna",
    questionsAndAnswers: [
      {
        id: 0,
        question: "Vilket år bildades Eskilstuna United?",
        answers: [
          { answer: "2000", isCorrect: !1 },
          { answer: "2002", isCorrect: !0 },
          { answer: "2004", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "Två damklubbar slogs samman när Eskilstuna United bildades. Vilka",
        answers: [
          { answer: "Tunafors SK och Slagsta IK", isCorrect: !0 },
          { answer: "Slagsta IK och Hällbybrunns IF", isCorrect: !1 },
          { answer: "Torshälla/Nyby IS och Tunafors SK", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "Eskilstuna Uniteds samhällsansvarsplattform arbetar för att stödja utsatta grupper i samhället och heter:",
        answers: [
          { answer: "Love United", isCorrect: !1 },
          { answer: "Heart United", isCorrect: !1 },
          { answer: "Hjärta United", isCorrect: !0 },
        ],
      },
      {
        id: 3,
        question:
          "Eskilstuna Uniteds samhällsansvarsplattform arbetar för att stödja utsatta grupper i samhället och heter:",
        answers: [
          { answer: "Love United", isCorrect: !1 },
          { answer: "Heart United", isCorrect: !1 },
          { answer: "Hjärta United", isCorrect: !0 },
        ],
      },
      {
        id: 4,
        question:
          "Eskilstuna United toppade Elitettan 2013 och kvalificerade sig därmed för spel i OBOS Damallsvenskan. Vad blev lagets slutpoäng?",
        answers: [
          { answer: "58 poäng", isCorrect: !1 },
          { answer: "60 poäng", isCorrect: !0 },
          { answer: "62 poäng", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Vilket år hamnade Eskilstuna United tvåa i OBOS Damallsvenskan – och kvalificerade sig därmed till Champions League?",
        answers: [
          { answer: "2015", isCorrect: !0 },
          { answer: "2016", isCorrect: !1 },
          { answer: "2017", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "2015 satte Eskilstuna United ett nytt publikrekord på Nya Tunavallen när laget mötte dåvarande Kopparbergs/Göteborgs FC. Hur många åskådare satt i publiken?",
        answers: [
          { answer: "6 103 åskådare", isCorrect: !1 },
          { answer: "6 312 åskådare", isCorrect: !0 },
          { answer: "6 795 åskådare", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Nya Tunavallen är Eskilstuna Uniteds hemmaarena. Mellan vilka år byggdes originalarenan?",
        answers: [
          { answer: "1923-1924", isCorrect: !0 },
          { answer: "1925-1926", isCorrect: !1 },
          { answer: "1927-1928", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "Eskilstuna United spelade sin första säsong i Champions League 2016, men åkte ut i åttondelsfinalen. Vad heter det tyska laget som besegrade svenskorna?",
        answers: [
          { answer: "Vfl Wolfsburg", isCorrect: !0 },
          { answer: "FFC Frankfurt", isCorrect: !1 },
          { answer: "SC Freiburg", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          "I augusti 2021 röstades anfallaren Mia Jalkerud fram till Månadens Spelare i OBOS Damallsvenskan. Vilken är Mias moderklubb?",
        answers: [
          { answer: "Stuvsta IF", isCorrect: !1 },
          { answer: "Stureby FF", isCorrect: !1 },
          { answer: "Enskede IK", isCorrect: !0 },
        ],
      },
      {
        id: 10,
        question:
          "Dåvarande Eskilstuna United-spelaren Gaëlle Enganamouit vann skytteligan i OBOS Damallsvenskan 2015, med totalt:",
        answers: [
          { answer: "18 mål", isCorrect: !0 },
          { answer: "20 mål", isCorrect: !1 },
          { answer: "23 mål", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "Elin Rombing är högerytter i Eskilstuna United och har det lite annorlunda smeknamnet:",
        answers: [
          { answer: "Rocky", isCorrect: !1 },
          { answer: "Ronny", isCorrect: !1 },
          { answer: "Robban", isCorrect: !0 },
        ],
      },
      {
        id: 12,
        question:
          "Elena Sadiku är numera assisterande tränare i Eskilstuna United, ett lag hon själv spelade för 2011. Vad heter den danska klubben där hon tidigare varit huvudtränare?",
        answers: [
          { answer: "HB Køge", isCorrect: !1 },
          { answer: "FC Nordsjælland", isCorrect: !1 },
          { answer: "Fortuna Hjørring", isCorrect: !0 },
        ],
      },
      {
        id: 13,
        question:
          "Vilken av Eskilstuna Uniteds spelare är inte uttagen för spel i U23-landslaget 2022?",
        answers: [
          { answer: "Felicia Rogic", isCorrect: !0 },
          { answer: "Paulina Nyström", isCorrect: !1 },
          { answer: "Felicia Saving", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Genom att köpa Eskilstuna Uniteds nya matchtröja stöttar du lagets jämställdhetsarbete. Hur lyder texten på tröjan?",
        answers: [
          { answer: "JAG2030", isCorrect: !0 },
          { answer: "VI2030", isCorrect: !1 },
          { answer: "EU2030", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question:
          "Förutom fotbollen har mittfältaren Linn Vickius ett annat stort intresse, nämligen:",
        answers: [
          { answer: "Bilar", isCorrect: !0 },
          { answer: "Musik", isCorrect: !1 },
          { answer: "Matlagning", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "Eskilstuna United var en av finalisterna till Svenska Jämställdhetspriset 2022, som vanns av:",
        answers: [
          { answer: "Uppsala Kommun", isCorrect: !1 },
          { answer: "Arbetsförmedlingen", isCorrect: !0 },
          { answer: "Danske Bank", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Försvararen Anna Oscarsson har spelat för Eskilstuna United sedan säsongen 2020. Vilket är hennes tröjnummer i truppen?",
        answers: [
          { answer: "Nr 7", isCorrect: !1 },
          { answer: "Nr 14", isCorrect: !1 },
          { answer: "Nr 20", isCorrect: !0 },
        ],
      },
      {
        id: 18,
        question:
          "Eskilstuna United inledde 2021 ett samarbete för att öka jämställdheten och motverka stigmat kring mens. Samarbetet innebär att såväl a-lagsspelare som akademilagsspelare får gratis mensskydd. Vilken är samarbetspartnern?",
        answers: [
          { answer: "Libresse", isCorrect: !0 },
          { answer: "Always", isCorrect: !1 },
          { answer: "DeoDoc", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Eskilstuna United landade på en tredjeplats över lagen som drog mest publik i OBOS Damallsvenskan 2021. Vad var lagets publiksnitt hemma under pandemiåret?",
        answers: [
          { answer: "416", isCorrect: !1 },
          { answer: "592", isCorrect: !0 },
          { answer: "710", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "Målvakten Emma Holmgren hann bara spela en halv säsong för Eskilstuna United under 2021 innan hon värvades av fransk klubb, nämligen:",
        answers: [
          { answer: "Lyon", isCorrect: !0 },
          { answer: "Le Havre", isCorrect: !1 },
          { answer: "Dijon", isCorrect: !1 },
        ],
      },
      {
        id: 21,
        question:
          "Nyförvärvet Joanna Aalstad Bækkelund har tidigare varit dansare och då tränat:",
        answers: [
          { answer: "Hiphop", isCorrect: !0 },
          { answer: "Balett", isCorrect: !1 },
          { answer: "Jazzdans", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "Felicia “Felle” Rogic har tröjnummer 16 i Eskilstuna Uniteds trupp. Vilken är hennes position?",
        answers: [
          { answer: "Back", isCorrect: !1 },
          { answer: "Mittfältare", isCorrect: !1 },
          { answer: "Forward", isCorrect: !0 },
        ],
      },
      {
        id: 23,
        question:
          "Eskilstuna United vann årets första träningsmatch på Tunavallen med 7-0. Vilket var motståndarlaget?",
        answers: [
          { answer: "Lillestrøm", isCorrect: !1 },
          { answer: "GUSK", isCorrect: !1 },
          { answer: "Sandvikens IF", isCorrect: !0 },
        ],
      },
      {
        id: 24,
        question:
          "Eskilstuna United vann premiärmatchen i OBOS Damallsvenskan 2022 mot:",
        answers: [
          { answer: "Kristianstads DFF", isCorrect: !1 },
          { answer: "Hammarby", isCorrect: !0 },
          { answer: "Piteå IF DFF", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Halimatu Ayinde är mittfältare i Eskilstuna United och spelar även i det nigerianska landslaget. Vilken klubb har hon inte spelat för?",
        answers: [
          { answer: "Asarums IF", isCorrect: !1 },
          { answer: "FK Minsk", isCorrect: !1 },
          { answer: "Lyn", isCorrect: !0 },
        ],
      },
      {
        id: 26,
        question:
          "Ngozi Sonia Okobi och Halimatu Ayinde i Eskilstuna United tillhör samma nigerianska moderklubb, nämligen:",
        answers: [
          { answer: "Delta Queens FC", isCorrect: !0 },
          { answer: "Nasarawa Amazons FC", isCorrect: !1 },
          { answer: "River Angels FC", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question:
          "När började Eskilstuna Uniteds mittfältare Noor Hoelsbrekken Eckhoff att spela fotboll?",
        answers: [
          { answer: "Som sexåring", isCorrect: !1 },
          { answer: "Som sjuåring", isCorrect: !0 },
          { answer: "Som nioåring", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "2022 firar Eskilstuna United en stor årsdag. Hur många år fyller föreningen?",
        answers: [
          { answer: "15 år", isCorrect: !1 },
          { answer: "20 år", isCorrect: !0 },
          { answer: "25 år", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Vad symboliserar skölden i Eskilstuna Uniteds nya klubbmärke?",
        answers: [
          { answer: "Styrka", isCorrect: !1 },
          { answer: "Stolthet", isCorrect: !0 },
          { answer: "Sammanhållning", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 4,
    team: "Hammarby",
    questionsAndAnswers: [
      {
        id: 0,
        question: "Vilket år grundades Hammarbys damfotbollslag?",
        answers: [
          { answer: "1968", isCorrect: !1 },
          { answer: "1970", isCorrect: !0 },
          { answer: "1972", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "1889, då Hammarby först bildades, utövade medlemmarna inte fotboll, utan:",
        answers: [
          { answer: "Rodd", isCorrect: !0 },
          { answer: "Friidrott", isCorrect: !1 },
          { answer: "Tennis", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question: "Vilket år vann Hammarbys damer sitt första SM-guld?",
        answers: [
          { answer: "1985", isCorrect: !0 },
          { answer: "1990", isCorrect: !1 },
          { answer: "1993", isCorrect: !1 },
        ],
      },
      {
        id: 3,
        question: "Har Hammarbys damlag vunnit Svenska Cupen?",
        answers: [
          { answer: "Ja, 1994", isCorrect: !1 },
          { answer: "Ja, 1994 och 1995", isCorrect: !0 },
          { answer: "Nej", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "Med en andraplacering i Elitettan 2020 kvalificerade sig Hammarby för spel i OBOS Damallsvenskan 2021. Vilket lag kom etta det året?",
        answers: [
          { answer: "IF Brommapojkarna", isCorrect: !1 },
          { answer: "IFK Kalmar", isCorrect: !1 },
          { answer: "AIK", isCorrect: !0 },
        ],
      },
      {
        id: 5,
        question:
          "Vilken känd profil avslutade sin fotbollskarriär i Hammarby under 1990-talet?",
        answers: [
          { answer: "Elisabeth Leidinge", isCorrect: !1 },
          { answer: "Victoria “Vickan” Sandell Svensson", isCorrect: !1 },
          { answer: "Pia Sundhage", isCorrect: !0 },
        ],
      },
      {
        id: 6,
        question:
          "Hammarby hade det högsta publiksnittet i OBOS Damallsvenskan 2021. Vad var lagets publiksnitt hemma under pandemiåret?",
        answers: [
          { answer: "1 973", isCorrect: !1 },
          { answer: "2 119", isCorrect: !0 },
          { answer: "2 521", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Hammarby IP är hemmaarena för Hammarbys damer. Vad har arenan för planstorlek?",
        answers: [
          { answer: "105 x 65 meter", isCorrect: !0 },
          { answer: "105 x 68 meter", isCorrect: !1 },
          { answer: "105 x 70 meter", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "Mittfältaren Emilia Larsson är en av spelarna som gör upp om förstaplatsen i årets skytteliga. Vilken är hennes moderklubb?",
        answers: [
          { answer: "IFK Norrköping", isCorrect: !1 },
          { answer: "Linköpings FC", isCorrect: !1 },
          { answer: "IK Östria Lambohov", isCorrect: !0 },
        ],
      },
      {
        id: 9,
        question:
          "Vem röstades fram till Årets Bajenspelare två år på raken? (2019/2020)",
        answers: [
          { answer: "Elsa Karlsson", isCorrect: !1 },
          { answer: "Emma Jansson", isCorrect: !0 },
          { answer: "Emma Westin", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "2021 vann forwarden Nina Jakobsson utmärkelsen Årets Bajenmål för sin insats i matchen mot:",
        answers: [
          { answer: "Linköping FC", isCorrect: !1 },
          { answer: "Kristianstads DFF", isCorrect: !1 },
          { answer: "Piteå IF", isCorrect: !0 },
        ],
      },
      {
        id: 11,
        question:
          "Den 10 oktober 2021 slogs nytt publikrekord i OBOS Damallsvenskan, då Hammarby krossade AIK med 4-1 i derbyt på Tele2 Arena. Hur många supportrar hade tagit sig till matchen?",
        answers: [
          { answer: "17 983 supportrar", isCorrect: !1 },
          { answer: "18 537 supportrar", isCorrect: !0 },
          { answer: "19 201 supportrar", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Sverige är klara för fotbolls-VM 2023 i Australien och Nya Zeeland. Vilken Bajenspelare ingår i landslagstruppen?",
        answers: [
          { answer: "Madelen Janogy", isCorrect: !0 },
          { answer: "Adelina Engman", isCorrect: !1 },
          { answer: "Ellen Wangerheim", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Våren 2022 stod det klart att landslagsbacken Jonna Andersson återvänder hem för spel i Bajen. Vilket brittiskt lag lämnar hon?",
        answers: [
          { answer: "Chelsea", isCorrect: !0 },
          { answer: "Aston Villa", isCorrect: !1 },
          { answer: "Birmingham City", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Bajens nyförvärv Kyra Cooney-Cross och Courtney Nevin har tidigare varit lagkamrater i den australiska fotbollsklubben:",
        answers: [
          { answer: "Sydney FC", isCorrect: !1 },
          { answer: "Perth Glory", isCorrect: !1 },
          { answer: "Melbourne Victory", isCorrect: !0 },
        ],
      },
      {
        id: 15,
        question:
          "Hammarby tog sina första poäng för säsongen 2022 genom en 1-0-seger på hemmaplan. Vilket var motståndarlaget?",
        answers: [
          { answer: "Piteå IF", isCorrect: !0 },
          { answer: "Linköping FC", isCorrect: !1 },
          { answer: "Umeå IK", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "Vänsterbacken Ellen Schampi som nyligen anslöt till Bajen kommer senast från spel i Morön BK. Var i Västerbotten är Ellen född och uppvuxen?",
        answers: [
          { answer: "Skellefteå", isCorrect: !0 },
          { answer: "Robertsfors", isCorrect: !1 },
          { answer: "Dorotea", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Mittfältaren Ellen Gibson har burit grönvitt sedan barnsben och gjorde allsvensk debut redan som 15-åring. Vilket är hennes tröjnummer?",
        answers: [
          { answer: "Nr 5", isCorrect: !1 },
          { answer: "Nr 8", isCorrect: !0 },
          { answer: "Nr 11", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "Efter totalt 122 tävlingsmatcher i grönvitt har Helén Eke nu tagit farväl av Bajen. I dag spelar hon för:",
        answers: [
          { answer: "IK Uppsala", isCorrect: !0 },
          { answer: "Gamla Upsala SK", isCorrect: !1 },
          { answer: "Bergdalens IK", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Vilken spelare vann Hammarbys interna skytteliga 2021 med sammanlagt 10 mål?",
        answers: [
          { answer: "Emilia Larsson", isCorrect: !1 },
          { answer: "Emma Jansson", isCorrect: !1 },
          { answer: "Madelen Janogy", isCorrect: !0 },
        ],
      },
      {
        id: 20,
        question:
          "Det blev totalt 31 poäng för Hammarby i OBOS Damallsvenskan 2021, vilket resulterade i en:",
        answers: [
          { answer: "Femteplats", isCorrect: !1 },
          { answer: "Sjätteplats", isCorrect: !1 },
          { answer: "Sjundeplats", isCorrect: !0 },
        ],
      },
      {
        id: 21,
        question:
          "Hur många grönvita mål gjorde Bajen totalt under OBOS Damallsvenskan 2021?",
        answers: [
          { answer: "29 mål", isCorrect: !1 },
          { answer: "33 mål", isCorrect: !1 },
          { answer: "40 mål", isCorrect: !0 },
        ],
      },
      {
        id: 22,
        question:
          "Mittfältaren Emma Westin är äntligen tillbaka i grönvitt efter att ha slutfört sina studier vid ett amerikanskt universitet. Vad heter universitetet?",
        answers: [
          { answer: "University of California", isCorrect: !0 },
          { answer: "University of Pennsylvania", isCorrect: !1 },
          { answer: "University of Michigan", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "I år har Hammarby två uttalade lagkaptener i laget. Den ena är Alice Carlsson, som får bära bindeln för tredje året i rad. Vem är den andra lagkaptenen?",
        answers: [
          { answer: "Adelina Engman", isCorrect: !1 },
          { answer: "Hanna Folkesson", isCorrect: !0 },
          { answer: "Fanny Hjelm", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "2021 tvingades målvakten Britta Gynning avsluta sin karriär efter upprepade hjärnskakningar. Hur många tävlingsmatcher blev det totalt för Britta i Bajentröjan?",
        answers: [
          { answer: "25 matcher", isCorrect: !1 },
          { answer: "38 matcher", isCorrect: !1 },
          { answer: "47 matcher", isCorrect: !0 },
        ],
      },
      {
        id: 25,
        question:
          "Vilken Bajenspelare drog på sig flest gula kort (5 stycken) under OBOS Damallsvenskan 2021?",
        answers: [
          { answer: "Alice Carlsson", isCorrect: !0 },
          { answer: "Hanna Folkesson", isCorrect: !1 },
          { answer: "Eva Nyström", isCorrect: !1 },
        ],
      },
      {
        id: 26,
        question:
          "Kathrine Larsen är det senaste tillskottet till Bajens målvaktsbesättning och har tidigare spelat för bland annat Djurgården och norska Klepp IL. Hur lång är hon?",
        answers: [
          { answer: "178 cm", isCorrect: !1 },
          { answer: "180 cm", isCorrect: !1 },
          { answer: "182 cm", isCorrect: !0 },
        ],
      },
      {
        id: 27,
        question:
          "I fjol lämnade Hanna “Lunkan” Lundkvist sitt älskade Bajen efter att ha blivit klar för spel i spanska Primera División Femenina. Vilket är hennes nya lag?",
        answers: [
          { answer: "Atlético Madrid", isCorrect: !0 },
          { answer: "FC Barcelona", isCorrect: !1 },
          { answer: "Villarreal", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "Pablo Piñones-Arce är huvudtränare för Hammarby sedan 2020 och har själv spelat för Bajen i Allsvenskan. I vilken klubb avslutade han sin elitkarriär?",
        answers: [
          { answer: "Hammarby IF", isCorrect: !1 },
          { answer: "IF Brommapojkarna", isCorrect: !0 },
          { answer: "Östers IF", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Supporterföreningen Bajen Fans är helt fristående från Hammarby Fotboll och har tusentals medlemmar. När grundades den?",
        answers: [
          { answer: "1977", isCorrect: !1 },
          { answer: "1981", isCorrect: !0 },
          { answer: "1986", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 5,
    team: "Häcken",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "Hammarby var det lag som lockade mest publik under OBOS Damallsvenskan 2021. BK Häcken kom tvåa, med ett publiksnitt hemma på:",
        answers: [
          { answer: "463", isCorrect: !1 },
          { answer: "597", isCorrect: !0 },
          { answer: "685", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "Under 2022 har BK Häcken tagit fram en egen gravidpolicy, som ska göra det tryggare för spelarna att bilda familj. Ett annat lag i OBOS Damallsvenskan har också en sådan policy, nämligen:",

        answers: [
          { answer: "FC Rosengård", isCorrect: !0 },
          { answer: "Hammarby IF", isCorrect: !1 },
          { answer: "Djurgårdens IF", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "2021 togs verksamheten i Kopparbergs/Göteborg FC över av BK Häcken. 2020 vann Kopparbergs/Göteborg FC OBOS Damallsvenskan, med en slutpoäng på:",
        answers: [
          { answer: "45 poäng", isCorrect: !1 },
          { answer: "51 poäng", isCorrect: !1 },
          { answer: "54 poäng", isCorrect: !0 },
        ],
      },
      {
        id: 3,
        question:
          "2021 vann Stina Blackstenius skytteligan med totalt 17 mål. Inför säsongen lämnade hon BK Häcken efter att ha skrivit kontrakt med den brittiska storklubben:",
        answers: [
          { answer: "Chelsea", isCorrect: !1 },
          { answer: "Manchester City", isCorrect: !1 },
          { answer: "Arsenal", isCorrect: !0 },
        ],
      },
      {
        id: 4,
        question:
          "Hösten 2021 blev klubben historisk då damerna gick vidare till spel i Champions League. Vad heter det norska laget som BK Häcken slog ut med totalt 6-3?",
        answers: [
          { answer: "Brann", isCorrect: !1 },
          { answer: "Vålerenga", isCorrect: !0 },
          { answer: "Lyn", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Efter att ha förlorat hemmamatchen mot Bayern München i december 2021 släcktes hoppet om ett avancemang i Champions League. Vad blev matchresultatet?",
        answers: [
          { answer: "5-1", isCorrect: !0 },
          { answer: "5-0", isCorrect: !1 },
          { answer: "4-0", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "Bravida Arena är BK Häckens hemmarena. Hur många får plats i publiken?",
        answers: [
          { answer: "6012 personer", isCorrect: !1 },
          { answer: "6316 personer", isCorrect: !0 },
          { answer: "6705 personer", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Hemmaarenan Bravida Arena invigdes 2015. Vad är det för underlag på arenan?",
        answers: [
          { answer: "Naturgräs", isCorrect: !1 },
          { answer: "Hybridgräs", isCorrect: !1 },
          { answer: "Konstgräs", isCorrect: !0 },
        ],
      },
      {
        id: 8,
        question:
          "BK Häckens föräldralediga målvakt Loes Geurts har tidigare spelat för en av Europas främsta klubbar, nämligen:",
        answers: [
          { answer: "Olympique Lyonnais", isCorrect: !1 },
          { answer: "Paris Saint-Germain", isCorrect: !0 },
          { answer: "FC Barcelona", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          "2021 vann BK Häckens nyförvärv Aisha Masaka skytteligan i sitt hemland med imponerande 35 mål. Var är hon född?",
        answers: [
          { answer: "Tanzania", isCorrect: !0 },
          { answer: "Kenya", isCorrect: !1 },
          { answer: "Zimbabwe", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Den 26 maj 2022 möttes BK Häcken och FC Rosengård i Svenska Cupen-finalen. Vilket lag slog BK Häcken ut med 2-1 i semifinalen?",
        answers: [
          { answer: "Kristianstads DFF", isCorrect: !1 },
          { answer: "Eskilstuna United", isCorrect: !1 },
          { answer: "Hammarby", isCorrect: !0 },
        ],
      },
      {
        id: 11,
        question:
          "När BK Häcken besegrade Umeå IK med 5-0 i september stod en av spelarna för ett hattrick. Vem?",
        answers: [
          {
            answer: "Pauline Hammarlund",
            isCorrect: !0,
          },
          { answer: "Anna Csiki", isCorrect: !1 },
          { answer: "Mille Gejl Jensen", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Under vintern kom Marika Bergman tillbaka till Göteborg för att skriva på för BK Häcken. Vilket klubb lämnade hon?",
        answers: [
          { answer: "IK Uppsala", isCorrect: !0 },
          { answer: "IFK Norrköping", isCorrect: !1 },
          { answer: "Älvsjö AIF FF", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Supertalangen Rosa Kafaji ådrog sig en fraktur under sin debutmatch med BK Häcken och blir borta resten av säsongen. Hur många minuter hann hon spela innan olyckan var framme?",
        answers: [
          { answer: "4 minuter", isCorrect: !1 },
          { answer: "6 minuter", isCorrect: !0 },
          { answer: "10 minuter", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Filippa “Pippo” Curmark glänste med ett sensationellt hattrick när BK Häcken besegrade AIK under långfredagen. I vilken matchminut nickade hon in det första målet?",
        answers: [
          { answer: "10:e minuten", isCorrect: !1 },
          { answer: "19:e minuten", isCorrect: !1 },
          { answer: "26:e minuten", isCorrect: !0 },
        ],
      },
      {
        id: 15,
        question:
          "I början av 2022 åkte BK Häcken på träningsläger med såväl dam- som herrlaget. I vilken spansk stad ligger träningsanläggningen där klubben bodde?",
        answers: [
          { answer: "Alicante", isCorrect: !1 },
          { answer: "Málaga", isCorrect: !1 },
          { answer: "Marbella", isCorrect: !0 },
        ],
      },
      {
        id: 16,
        question:
          "BK Häckens huvudtränare Robert Vilahamn vann under sin elitkarriär ett svenskt fotbollspris. Vilket?",
        answers: [
          { answer: "Svenska Guldskon", isCorrect: !0 },
          { answer: "Årets komet", isCorrect: !1 },
          { answer: "Kristallkulan", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          " Vilken spelare utsågs hösten 2022 till BK Häckens nya lagkapten?",
        answers: [
          { answer: "Dóra Zeller", isCorrect: !1 },
          { answer: "Elin Rubensson", isCorrect: !1 },
          { answer: "Filippa Curmark ", isCorrect: !0 },
        ],
      },
      {
        id: 18,
        question:
          "Mittfältaren Elin Rubensson förblir gulsvart med ett nytt avtal som sträcker sig över säsongen 2025. Vilket är Elins tröjnummer i BK Häcken?",
        answers: [
          { answer: "Nr 7", isCorrect: !1 },
          { answer: "Nr 10", isCorrect: !0 },
          { answer: "Nr 14", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Elma Junttila Nelhage var i fjol utlånad till norska Kolbotn, men är nu tillbaka i BK Häcken. Vilken klubb är Elmas moderklubb?",
        answers: [
          { answer: "Göteborgs FF", isCorrect: !1 },
          { answer: "Hisingsbacka FC", isCorrect: !1 },
          { answer: "IK Zenith", isCorrect: !0 },
        ],
      },
      {
        id: 20,
        question:
          "BK Häckens Rosa Kafaji är utsedd till en av världens mest lovande unga talanger av tidningen GOAL. Tidningen, som varje år utser 20 personer, gav Kafaji plats nummer:",
        answers: [
          { answer: "6", isCorrect: !1 },
          { answer: "11", isCorrect: !0 },
          { answer: "19", isCorrect: !1 },
        ],
      },
      {
        id: 21,
        question:
          "På vilken arena spelades Svenska Cup-finalen mellan BK Häcken och FC Rosengård?",
        answers: [
          { answer: "Malmö IP", isCorrect: !0 },
          { answer: "Bravida Arena", isCorrect: !1 },
          { answer: "Gamla Ullevi", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "Schweiziskan Livia Peng är längst av samtliga målvakter i BK Häcken. Hur lång är hon?",
        answers: [
          { answer: "172 cm", isCorrect: !1 },
          { answer: "174 cm", isCorrect: !0 },
          { answer: "177 cm", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "Vem röstades fram som Årets bästa spelare i BK Häcken 2021 och vinnare av Guldkängan?",
        answers: [
          { answer: "Johanna Rytting Kaneryd", isCorrect: !1 },
          { answer: "Filippa Curmark", isCorrect: !1 },
          { answer: "Stina Blackstenius", isCorrect: !0 },
        ],
      },
      {
        id: 24,
        question:
          "Vilket lag möter BK Häcken i Champion League-kvalets sista omgång?",
        answers: [
          { answer: "Fortuna Hjørring", isCorrect: !1 },
          { answer: "SK Brann", isCorrect: !1 },
          { answer: "Paris Saint-Germain", isCorrect: !0 },
        ],
      },
      {
        id: 25,
        question:
          "På transferfönstrets sista dag stod det klart att mittfältaren Julia Karlernäs lämnar BK Häcken för spel i italienska Serie A. Vad heter hennes nya klubb?",
        answers: [
          { answer: "Fiorentina", isCorrect: !1 },
          { answer: "Como", isCorrect: !0 },
          { answer: "Roma", isCorrect: !1 },
        ],
      },
      {
        id: 26,
        question:
          "I fjol lånades anfallaren Molly Johansson ut till ett annat lag, nämligen;",
        answers: [
          { answer: "Lidköpings FK", isCorrect: !0 },
          { answer: "Eskilstuna United", isCorrect: !1 },
          { answer: "Linköping FC", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question:
          "Vilken var den enda BK Häcken-spelaren som drog på sig ett rött kort under OBOS Damallsvenskan 2021?",
        answers: [
          { answer: "Luna Nørgaard Gewitz", isCorrect: !1 },
          { answer: "Elin Rubensson", isCorrect: !1 },
          { answer: "Ingen", isCorrect: !0 },
        ],
      },
      {
        id: 28,
        question:
          "Nyförvärvet Lisa Löwing kommer senast från Göteborgs DFF, men var inledde hon sin karriär?",
        answers: [
          { answer: "Torslanda IK", isCorrect: !0 },
          { answer: "Sävedalens IF", isCorrect: !1 },
          { answer: "Ullareds IK", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Lotta Ökvist blir borta från spel i BK Häcken under resten av säsongen. Varför?",
        answers: [
          { answer: "Hon är skadad", isCorrect: !1 },
          { answer: "Hon är utlånad", isCorrect: !0 },
          { answer: "Hon har avslutat sin karriär", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 6,
    team: "IFK Kalmar",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "Vilket år spelade IFK Kalmar sin första säsong i OBOS Damallsvenskan?",
        answers: [
          { answer: "2016", isCorrect: !1 },
          { answer: "2018", isCorrect: !0 },
          { answer: "2019", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "Den första säsongen i OBOS Damallsvenskan hamnade IFK Kalmar sist i tabellen. Hur många poäng fick laget totalt?",
        answers: [
          { answer: "3 poäng", isCorrect: !0 },
          { answer: "5 poäng", isCorrect: !1 },
          { answer: "7 poäng", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "IFK Kalmar var en av de första föreningarna att bedriva damfotboll i Sverige på 1970-talet. Från vilket år blev den en renodlad damklubb?",
        answers: [
          { answer: "2000", isCorrect: !1 },
          { answer: "2002", isCorrect: !1 },
          { answer: "2005", isCorrect: !0 },
        ],
      },
      {
        id: 3,
        question:
          "Vilket år vann IFK Kalmar Division 1 östra Götaland med totalt 59 poäng?",
        answers: [
          { answer: "2012", isCorrect: !1 },
          { answer: "2014", isCorrect: !0 },
          { answer: "2015", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "IFK Kalmars Tabby Tindell hamnade på en andraplats i Elitettans skytteliga 2021. Hur många mål gjorde hon under säsongen?",
        answers: [
          { answer: "16 mål", isCorrect: !1 },
          { answer: "19 mål", isCorrect: !0 },
          { answer: "22 mål", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Vilken spelare i IFK Kalmar drog på sig flest gula kort (5 stycken) under Elitettan 2021?",
        answers: [
          { answer: "Ingrid Wixner", isCorrect: !0 },
          { answer: "Mimmi Asperot", isCorrect: !1 },
          { answer: "Amanda Persson", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "Under säsongen 2021 sköt Mimmi Asperot in 11 mål för IFK Kalmar. Anfallaren har nu gått vidare till en annan klubb, nämligen:",
        answers: [
          { answer: "IFK Norrköping", isCorrect: !0 },
          { answer: "Linköping FC", isCorrect: !1 },
          { answer: "BK Häcken", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Arielle Ship är ett av IFK Kalmars nyförvärv inför säsongen i OBOS Damallsvenskan 2022. Vilken klubb spelade hon för senast?",
        answers: [
          { answer: "Washington Spirit", isCorrect: !1 },
          { answer: "Utah Royals", isCorrect: !1 },
          { answer: "Kansas City", isCorrect: !0 },
        ],
      },
      {
        id: 8,
        question:
          "Gröndals IP är IFK Kalmars hemmaplan. Vad är det för underlag på Gröndals IP?",
        answers: [
          { answer: "Naturgräs", isCorrect: !0 },
          { answer: "Konstgräs", isCorrect: !1 },
          { answer: "Hybridgräs", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          "När Tabby Tindell utsågs till Månadens Spelare i Elitettan i fjol skänktes prissumman till IFK Kalmars CSR-projekt. Hur stor är summan i Hejapriset som delas ut av Svenska Spel?",
        answers: [
          { answer: "5 000 kr", isCorrect: !1 },
          { answer: "10 000 kr", isCorrect: !0 },
          { answer: "15 000 kr", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Mittfältaren Elma Smajic blev 2021 uttagen till två landskamper i ett U19-lag. För vilket land spelade hon?",
        answers: [
          { answer: "Sverige", isCorrect: !1 },
          { answer: "Slovenien", isCorrect: !1 },
          { answer: "Bosnien-Hercegovina", isCorrect: !0 },
        ],
      },
      {
        id: 11,
        question:
          "Mittfältaren Astrid Larsson kommer närmast från spel i Piteå IF och spelar nu sin första säsong med IFK Kalmar. Vilket är hennes tröjnummer i truppen?",
        answers: [
          { answer: "Nr 9", isCorrect: !0 },
          { answer: "Nr 15", isCorrect: !1 },
          { answer: "Nr 22", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Vem utsågs till Årets Spelare 2021 av Smålands FF och Sportjournalisternas klubb Småland?",
        answers: [
          { answer: "Klara Rybrink", isCorrect: !0 },
          { answer: "Tabby Tindell", isCorrect: !1 },
          { answer: "Mimmi Asperot", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Annie Williams, med tröjnummer 24 i IFK Kalmars trupp, har tidigare spelat i den italienska ligan. Vilket lag tillhörde hon då?",
        answers: [
          { answer: "Napoli", isCorrect: !1 },
          { answer: "Fiorentina", isCorrect: !1 },
          { answer: "SS Lazio", isCorrect: !0 },
        ],
      },
      {
        id: 14,
        question:
          "IFK Kalmars målvakt Karina Kork var en av truppens spelare som blev kallad till VM-kvalet 2022 för landslaget i:",
        answers: [
          { answer: "Finland", isCorrect: !1 },
          { answer: "Island", isCorrect: !1 },
          { answer: "Estland", isCorrect: !0 },
        ],
      },
      {
        id: 15,
        question:
          "Det blev en tuff start i OBOS Damallsvenskan för IFK Kalmar, som förlorade på bortaplan mot Kristianstads DFF. Vad blev matchresultatet?",
        answers: [
          { answer: "4-0", isCorrect: !0 },
          { answer: "4-1", isCorrect: !1 },
          { answer: "3-0", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question: "Vem bär lagkaptensbindeln i IFK Kalmar?",
        answers: [
          { answer: "Alyssa Walker", isCorrect: !1 },
          { answer: "Paige Culver", isCorrect: !0 },
          { answer: "Allison Pantuso", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Vera Anderssons kontrakt med IFK Kalmar är hennes första A-lagskontrakt. Vilken är hennes moderklubb?",
        answers: [
          { answer: "Lindals IF", isCorrect: !0 },
          { answer: "Kalmar Södra IF", isCorrect: !1 },
          { answer: "Ramdala IF", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question: "Hur många medlemmar har IFK Kalmar på ett ungefär?",
        answers: [
          { answer: "Ca 400 medlemmar", isCorrect: !0 },
          { answer: "Ca 700 medlemmar", isCorrect: !1 },
          { answer: "Ca 1 000 medlemmar", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "David Roufpanah är ny huvudtränare i IFK Kalmar. Han har tidigare arbetat som scout för ett lag i Allsvenskan, nämligen:",
        answers: [
          { answer: "IK Sirius", isCorrect: !0 },
          { answer: "Kalmar FF", isCorrect: !1 },
          { answer: "Malmö FF", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "I vilket nordiskt land spelade mittfältaren Katie Pingel innan hon skrev på för IFK Kalmar?",
        answers: [
          { answer: "Norge", isCorrect: !1 },
          { answer: "Finland", isCorrect: !1 },
          { answer: "Island", isCorrect: !0 },
        ],
      },
      {
        id: 21,
        question:
          "Målvakten Aubrei Corder var ett av namnen på Barometerns lista över årets 50 största sportprofiler 2021. Vilken placering fick hon på listan?",
        answers: [
          { answer: "Nr 8", isCorrect: !1 },
          { answer: "Nr 13", isCorrect: !0 },
          { answer: "Nr 24", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "Den isländska landslagsstjärnan Hallbera Gísladóttir är ett av IFK Kalmars nyförvärv inför säsongen 2022. I vilket damallsvenskt lag spelade hon dessförinnan?",
        answers: [
          { answer: "Hammarby", isCorrect: !1 },
          { answer: "BK Häcken", isCorrect: !1 },
          { answer: "AIK", isCorrect: !0 },
        ],
      },
      {
        id: 23,
        question:
          "Mittfältaren Andrea Thorisson valde att stanna kvar i Elitettan när IFK Kalmar avancerade till OBOS Damallsvenskan. Vad heter hennes nya klubb?",
        answers: [
          { answer: "Gamla Upsala FK", isCorrect: !1 },
          { answer: "IK Uppsala", isCorrect: !0 },
          { answer: "Älvsjö AIK", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Wilma Finnman är äntligen tillbaka i blått efter en säsong i BP. Vilket år utsågs hon till Årets Genombrott i Elitettan?",
        answers: [
          { answer: "2018", isCorrect: !1 },
          { answer: "2019", isCorrect: !0 },
          { answer: "2020", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Amanda Persson var en av tjejerna som spelade flest matcher för IFK Kalmar säsongen 2021, med:",
        answers: [
          { answer: "22 matcher", isCorrect: !1 },
          { answer: "25 matcher", isCorrect: !0 },
          { answer: "27 matcher", isCorrect: !1 },
        ],
      },
      {
        id: 26,
        question:
          "Målvakten Sara Björck kommer inte spela för IFK Kalmar i OBOS Damallsvenskan 2022. Varför?",
        answers: [
          { answer: "Hon har bytt klubb till Kvarnsvedens IK", isCorrect: !1 },
          { answer: "Hon är skadad", isCorrect: !1 },
          { answer: "Hon har slutat", isCorrect: !0 },
        ],
      },
      {
        id: 27,
        question:
          "2017 kvalificerade sig IFK Kalmar för spel i OBOS Damallsvenskan med en andraplats i Elitettan. Vilket lag toppade tabellen det året med 69 poäng?",
        answers: [
          { answer: "Växjö DFF", isCorrect: !0 },
          { answer: "AIK", isCorrect: !1 },
          { answer: "Umeå IK", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "Precis som backen Klara Rybrink valde också anfallaren Tabby Tindell att flytta till en annan klubb inför säsongen 2022, nämligen:",
        answers: [
          { answer: "Kristianstads DFF", isCorrect: !0 },
          { answer: "Linköping FC", isCorrect: !1 },
          { answer: "FC Rosengård", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Backen Paige Culver har tidigare spelat för den franska klubben Bordeaux. Var är hon född?",
        answers: [
          { answer: "Storbritannien", isCorrect: !1 },
          { answer: "USA", isCorrect: !1 },
          { answer: "Kanada", isCorrect: !0 },
        ],
      },
    ],
  },
  {
    id: 7,
    team: "Kristianstad",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "Vilket år kvalificerade sig Kristianstads DFF åter för spel OBOS Damallsvenskan efter flera säsonger i Söderettan?",
        answers: [
          { answer: "2006", isCorrect: !1 },
          { answer: "2008", isCorrect: !0 },
          { answer: "2009", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "Vilket år bytte fotbollsföreningen namn från Kristianstad/Wä DFF till nuvarande Kristianstads DFF?",
        answers: [
          { answer: "1998", isCorrect: !1 },
          { answer: "2002", isCorrect: !0 },
          { answer: "2006", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "Vilket år spelade laget sin allra första säsong i OBOS Damallsvenskan, då under namnet Kristianstad/Wä DFF?",
        answers: [
          { answer: "1996", isCorrect: !1 },
          { answer: "1999", isCorrect: !0 },
          { answer: "2002", isCorrect: !1 },
        ],
      },
      {
        id: 3,
        question:
          "Kristianstads DFF landade på en tredjeplacering i OBOS Damallsvenskan 2021, med:",
        answers: [
          { answer: "32 poäng", isCorrect: !1 },
          { answer: "35 poäng", isCorrect: !0 },
          { answer: "38 poäng", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "Mittfältaren Anna Welin placerade sig på plats 13 i skytteligan 2021. Hur många mål gjorde hon totalt under säsongen?",
        answers: [
          { answer: "6 mål", isCorrect: !0 },
          { answer: "7 mål", isCorrect: !1 },
          { answer: "8 mål", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Vem i KDFF spelade flest matcher från start under säsongen 2021?",
        answers: [
          { answer: "Josefine Rybrink", isCorrect: !0 },
          { answer: "Mia Carlsson", isCorrect: !1 },
          { answer: "Sif Attladottir", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "2017 skedde en stor förändring på Kristianstad Fotbollsarena, lagets hemmaplan. Vad?",
        answers: [
          {
            answer: "Arenan utökades med större publikkapacitet",
            isCorrect: !1,
          },
          { answer: "Nya baracker byggdes", isCorrect: !1 },
          { answer: " Hybridgräs installerades", isCorrect: !0 },
        ],
      },
      {
        id: 7,
        question:
          "Ett av lagets nyförvärv kommer senast från spel i Elitettan, där hon hamnade tvåa i skytteligan 2021 med totalt 19 mål. Vad heter hon? ",
        answers: [
          { answer: "Emmi Alanen", isCorrect: !1 },
          { answer: "Klara Rybrink ", isCorrect: !1 },
          { answer: "Tabby Tindell ", isCorrect: !0 },
        ],
      },
      {
        id: 8,
        question:
          "Josefine Rybrinks lillasyster Klara är nu en del av truppen inför säsongen 2022. Vilken lag lämnade hon?",
        answers: [
          { answer: "Växjö DFF", isCorrect: !1 },
          { answer: "IFK Kalmar", isCorrect: !0 },
          { answer: "Sundsvalls DFF", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          "Sverige är klara för fotbolls-VM 2023 i Australien och Nya Zeeland. Ingick några spelare från KDFF i VM-kvaltruppen 2022? ",
        answers: [
          { answer: "Ja, Mia Carlsson", isCorrect: !1 },
          { answer: "Ja, Mia Carlsson och Tilda Sandén ", isCorrect: !1 },
          { answer: "Nej", isCorrect: !0 },
        ],
      },
      {
        id: 10,
        question:
          "2021 hamnade Kristianstads DFF på en niondeplats över lagen som lockade mest publik i OBOS Damallsvenskan. Vad var lagets publiksnitt hemma under pandemiåret?",
        answers: [
          { answer: "290", isCorrect: !0 },
          { answer: "334", isCorrect: !1 },
          { answer: "416", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "2013/2014 tog Kristianstads DFF silver i Svenska Cupen, lagets hittills bästa placering. Vilket lag vann finalen med 2-1?",
        answers: [
          { answer: "Linköpings FC", isCorrect: !0 },
          { answer: "KIF Örebro ", isCorrect: !1 },
          { answer: "FC Rosengård", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Fansen har nu fått säga farväl av lagkaptenen Josefine Rybrink, som skrivit på för en annan klubb. Vilken?",
        answers: [
          { answer: "AIK", isCorrect: !1 },
          { answer: "BK Häcken", isCorrect: !0 },
          { answer: "Linköping FC", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Vem eller vilka bär lagkaptensbindeln i KDFF efter att Josefine Rybrink lämnat klubben? ",
        answers: [
          { answer: "Alice Nilsson och Sheila van den Bulk", isCorrect: !0 },
          { answer: "Alice Nilsson", isCorrect: !1 },
          { answer: "Emmi Alanen", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "2021 tog Champions League-drömmen slut efter förlusten mot franska Bordeaux. Vad blev matchresultatet?",
        answers: [
          { answer: "3-0", isCorrect: !1 },
          { answer: "3-1", isCorrect: !0 },
          { answer: "4-0", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question:
          "Vad heter det danska lag som KDFF besegrade på hemmaplan – och därmed gick vidare i kvalet för Champions League 2021?",
        answers: [
          { answer: "Brøndby IF", isCorrect: !0 },
          { answer: "Fortuna Hjørring", isCorrect: !1 },
          { answer: "HB Køge", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "Efter sex år i Djurgården välkomnar vi nu Sheila van den Bulk till KDFF. Vilket är hennes tröjnummer i laget?",
        answers: [
          { answer: "Nr 4", isCorrect: !0 },
          { answer: "Nr 9", isCorrect: !1 },
          { answer: "Nr 18", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Efter sju säsonger har Amanda Edgren nu gått vidare till nya äventyr i spanska Sporting de Huelva. Hur många matcher spelade Amanda totalt i KDFF?",
        answers: [
          { answer: "139 matcher", isCorrect: !1 },
          { answer: "146 matcher", isCorrect: !1 },
          { answer: "152 matcher", isCorrect: !0 },
        ],
      },
      {
        id: 18,
        question:
          "Anfallaren Evelyne Viens är ett av lagets nya ansikten inför säsongen 2022, som lånats ut av den amerikanska klubben:",
        answers: [
          { answer: "Angel City FC", isCorrect: !1 },
          { answer: "NJ/NY Gotham FC", isCorrect: !0 },
          { answer: "Chicago Red Stars", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Vilken eller vilka KDFF-spelare ingick i det kanadensiska landslaget som mötte Sverige i OS-finalen 2021?",
        answers: [
          { answer: "Gabrielle Carle", isCorrect: !1 },
          { answer: "Delaney Baie Pridham", isCorrect: !1 },
          { answer: "Gabrielle Carle och Evelyne Viens", isCorrect: !0 },
        ],
      },
      {
        id: 20,
        question:
          "Mittbacken Therese Ivarsson är inte en del av truppen 2022. Varför?",
        answers: [
          { answer: "Hon är föräldraledig", isCorrect: !0 },
          { answer: "Hon är skadad", isCorrect: !1 },
          { answer: "Hon har avslutat sin karriär", isCorrect: !1 },
        ],
      },
      {
        id: 21,
        question:
          "Emmy Thyberg är ett av KDFF:s nyförvärv inför säsongen 2022. Vilken är hennes position?",
        answers: [
          { answer: "Back", isCorrect: !1 },
          { answer: "Mittfält", isCorrect: !1 },
          { answer: "Målvakt", isCorrect: !0 },
        ],
      },
      {
        id: 22,
        question:
          "Vilken motståndare mötte laget i OBOS Damallsvenskans säsongsfinal 2021, en match som KDFF också vann med 2-1? ",
        answers: [
          { answer: "Växjö DFF", isCorrect: !1 },
          { answer: "Piteå IF DFF", isCorrect: !0 },
          { answer: "Djurgården", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "Mittbacksklippan Sif Atladottir har nu flyttat hem till Island efter lång och trogen tjänst i KDFF. Hur många aktiva säsonger spelade hon för laget?",
        answers: [
          { answer: "8 säsonger ", isCorrect: !1 },
          { answer: "10 säsonger", isCorrect: !0 },
          { answer: "13 säsonger", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Mittfältaren Emmi Alanen, som fått tröjnummer 10 i KDFF, har lång erfarenhet av spel i OBOS Damallsvenskan. Var spelade hon senast? ",
        answers: [
          { answer: "Växjö DFF", isCorrect: !0 },
          { answer: "Umeå IK", isCorrect: !1 },
          { answer: "Vittsjö GIK", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Varje år utser Fotbollskanalen OBOS Damallsvenskans 25 bästa spelare. Eveliina Summanen knep plats nr 19 på listan, men vilken KDFF-spelare fick plats nr 18?",
        answers: [
          { answer: "Josefine Rybrink", isCorrect: !0 },
          { answer: "Alice Nilsson", isCorrect: !1 },
          { answer: "Sif Atladottir", isCorrect: !1 },
        ],
      },
      {
        id: 26,
        question:
          "Under vårsäsongen i fjol lånades KDFF:s Evelina Duljan ut till Växjö DFF, men är nu tillbaka i truppen. Hur gammal var hon när hon gjorde debut i OBOS Damallsvenskan 2017?",
        answers: [
          { answer: "13 år", isCorrect: !1 },
          { answer: "14 år", isCorrect: !0 },
          { answer: "16 år", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question:
          "Efter ett års utlån i KDFF är supertalangen Sveindis Jane Jonsdottir nu tillbaka för spel i:",
        answers: [
          { answer: "Breiðablik", isCorrect: !1 },
          { answer: "Vlf Wolfsburg", isCorrect: !0 },
          { answer: "Bayern München", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "2022 välkomnar KDFF den assisterande tränaren Alexander Fridlund. Men klubben är inte helt ny för Alexander, som tidigare varit ansvarig för klubbens:",
        answers: [
          { answer: "F8-lag", isCorrect: !1 },
          { answer: "F12-lag", isCorrect: !1 },
          { answer: "F19-lag", isCorrect: !0 },
        ],
      },
      {
        id: 29,
        question:
          "Säsongspremiären 2022 kunde inte ha fått en bättre start för KDFF. Vilket lag besegrades med 4-0? ",
        answers: [
          { answer: "IFK Kalmar", isCorrect: !0 },
          { answer: "Eskilstuna United", isCorrect: !1 },
          { answer: "Linköping FC", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 8,
    team: "Linköping",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "Linköping FC har inte alltid varit en renodlad damfotbollsklubb. Vilken sport bedrevs tidigare i klubben?",
        answers: [
          { answer: "Ishockey", isCorrect: !0 },
          { answer: "Friidrott", isCorrect: !1 },
          { answer: "Pingis", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question: "Hur många gånger har Linköping FC vunnit Svenska Cupen?",
        answers: [
          { answer: "3 gånger", isCorrect: !1 },
          { answer: "4 gånger", isCorrect: !1 },
          { answer: "5 gånger", isCorrect: !0 },
        ],
      },
      {
        id: 2,
        question:
          "2019 lämnade Kosovare Asllani sitt LFC för spel i spanska Real Madrid. Hur gammal var stjärnan när hon skrev sitt första kontrakt med klubben?",
        answers: [
          { answer: "13 år", isCorrect: !1 },
          { answer: "15 år", isCorrect: !1 },
          { answer: "17 år", isCorrect: !0 },
        ],
      },
      {
        id: 3,
        question:
          "LFC har vunnit OBOS Damallsvenskan hela tre gånger. Vilket år kammade laget hem guldet för första gången?",
        answers: [
          { answer: "2008", isCorrect: !1 },
          { answer: "2009", isCorrect: !0 },
          { answer: "2010", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "Vilken placering fick Linköping FC i OBOS Damallsvenskan 2021, med totalpoängen 31?",
        answers: [
          { answer: "Plats 5", isCorrect: !1 },
          { answer: "Plats 6", isCorrect: !0 },
          { answer: "Plats 7", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Linköping Arena invigdes 2013 och är lagets hemmaplan. Hur stor publikkapacitet har arenan? ",
        answers: [
          { answer: "6 200", isCorrect: !1 },
          { answer: "7 400", isCorrect: !0 },
          { answer: "8 000", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "Linköping Arena, hemmaplan för LFC, är en unik idrottsanläggning. Varför? ",
        answers: [
          { answer: "Den är byggd för damfotboll", isCorrect: !0 },
          {
            answer: "Det är den enda arenan i Sverige med hybridgräs",
            isCorrect: !1,
          },
          {
            answer:
              "Påståendet är felaktigt, arenan utmärker sig inte på något särskilt vis",
            isCorrect: !1,
          },
        ],
      },
      {
        id: 7,
        question:
          "Anfallsspelaren Uchenna Kanu gjorde flest mål för LFC under säsongen 2021, med totalt: ",
        answers: [
          { answer: "12 mål", isCorrect: !1 },
          { answer: "14 mål", isCorrect: !0 },
          { answer: "16 mål", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "Vilken spelare i Linköping FC drog på sig flest gula kort (7 stycken) i OBOS Damallsvenskan 2021? ",
        answers: [
          { answer: "Uchenna Kanu", isCorrect: !1 },
          { answer: "Nilla Fischer", isCorrect: !1 },
          { answer: "Petra Johansson", isCorrect: !0 },
        ],
      },
      {
        id: 9,
        question:
          "2021 var Hammarby det lag som lockade mest publik i OBOS Damallsvenskan. LFC hamnade på en sjätteplats, med ett publiksnitt hemma på:",
        answers: [
          { answer: "334 supportrar", isCorrect: !1 },
          { answer: "416 supportrar", isCorrect: !0 },
          { answer: "563 supportrar", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Uchenna Kanu slutade på en andraplats i skytteligan 2021. Vilken spelare i OBOS Damallsvenskan vann skytteligan, med 17 mål? ",
        answers: [
          { answer: "Stina Blackstenius, BK Häcken", isCorrect: !0 },
          { answer: "Madelen Janogy, Hammarby", isCorrect: !1 },
          { answer: "Olivia Schough, FC Rosengård", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "När Fotbollskanalen utsåg årets 25 bästa spelare i OBOS Damallsvenskan 2021 hamnade LFC:s Nilla Fischer på plats 24. Vilket är hennes tröjnummer i truppen?",
        answers: [
          { answer: "Nr 3", isCorrect: !1 },
          { answer: "Nr 5", isCorrect: !0 },
          { answer: "Nr 10", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Lejonflocken är LFC:s officiella supporterförening. Vilket år bildades den? ",
        answers: [
          { answer: "2003", isCorrect: !1 },
          { answer: "2011", isCorrect: !1 },
          { answer: "2016", isCorrect: !0 },
        ],
      },
      {
        id: 13,
        question:
          "Saori Takarada är ett av LFC:s nyförvärv inför säsongen 2022, och spelade senast för den amerikanska klubben: ",
        answers: [
          { answer: "Washington Spirit", isCorrect: !0 },
          { answer: "Orlando Pride", isCorrect: !1 },
          { answer: "NJ/NY Gotham FC", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Det blev totalt fem säsonger i LFC för lagkaptenen Frida Leonhardsen Maanum, som nu skrivit på för den engelska storklubben:",
        answers: [
          { answer: "Arsenal", isCorrect: !0 },
          { answer: "Manchester City", isCorrect: !1 },
          { answer: "Manchester United", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question:
          "Vilken spelare i Linköping FC röstades fram till Månadens Spelare i OBOS Damallsvenskan i april 2022? ",
        answers: [
          { answer: "Nilla Fischer", isCorrect: !1 },
          { answer: "Cornelia Kapocs", isCorrect: !1 },
          { answer: "Amalie Vangsgaard", isCorrect: !0 },
        ],
      },
      {
        id: 16,
        question:
          "2018 tog drömmen om Champions League slut för LFC efter förlust i kvartsfinalen. Vilket lag vann matchen? ",
        answers: [
          { answer: "Lyon", isCorrect: !1 },
          { answer: "Manchester City", isCorrect: !0 },
          { answer: "Vfl Wolfsburg", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "2022 lämnade mittfältsstjärnan Sofie Bredgaard LFC för att flytta närmare familjen. Vad heter hennes nya klubb?",
        answers: [
          { answer: "HB Køge", isCorrect: !1 },
          { answer: "BK Häcken", isCorrect: !1 },
          { answer: "FC Rosengård", isCorrect: !0 },
        ],
      },
      {
        id: 18,
        question:
          "2017 tog Linköping FC farväl av Magdalena Eriksson, som gick vidare för spel i storklubben Chelsea. Vilket år vann Magda Diamantbollen?",
        answers: [
          { answer: "2019", isCorrect: !1 },
          { answer: "2020", isCorrect: !0 },
          { answer: "2021", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          " Det blev seger för Linköping FC i säsongens första bortamatch mot Eskilstuna United på Tunavallen. Vad blev matchresultatet?",
        answers: [
          { answer: "3-0", isCorrect: !1 },
          { answer: "2-0", isCorrect: !0 },
          { answer: "2-1", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "Efter lång och trogen tjänst har Petra Johansson lagt fotbollsskorna på hyllan, men är nu tillbaka i klubben som Players Manager. Hur många år spelade Petra i LFC?",
        answers: [
          { answer: "10 år", isCorrect: !0 },
          { answer: "12 år", isCorrect: !1 },
          { answer: "14 år", isCorrect: !1 },
        ],
      },
      {
        id: 21,
        question:
          "2021 slogs nytt publikrekord i OBOS Damallsvenskan, när 18 537 supportrar såg derbyt mellan Hammarby och AIK. Vilket var det tidigare rekordet från 2008, då Linköping FC förlorade mot Umeå IK? ",
        answers: [
          { answer: "9 413", isCorrect: !0 },
          { answer: "9 752", isCorrect: !1 },
          { answer: "9 968", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "LFC-spelaren Cornelia Kapocs blev 2021 uttagen för landslagsspel i:",
        answers: [
          { answer: "Finska landslaget", isCorrect: !1 },
          { answer: "Svenska landslaget ", isCorrect: !1 },
          { answer: "Svenska U23-landslaget ", isCorrect: !0 },
        ],
      },
      {
        id: 23,
        question:
          "Inför 2022 har LFC förstärkt truppen med mittfältaren Johanna Svedberg från BK Häcken. Hon har nyligen varit utlånad för spel i Elitettan och klubben:",
        answers: [
          { answer: "Lidköpings FK", isCorrect: !0 },
          { answer: "IFK Norrköping FK", isCorrect: !1 },
          { answer: "IK Uppsala", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Det blev vinst i sista matchen för 2021 när AIK besegrades på hemmaplan med 2-0. Vem satte matchens båda mål?",
        answers: [
          { answer: "Alva Selerud", isCorrect: !1 },
          { answer: "Uchenna Kanu", isCorrect: !0 },
          { answer: "Yuka Momiki", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Den rutinerade målvakten Cajsa Andersson har förlängt sitt kontrakt i LFC med ytterligare ett år. Vilken är hennes moderklubb?",
        answers: [
          { answer: "Boo FF", isCorrect: !1 },
          { answer: "Piteå IF ", isCorrect: !1 },
          { answer: "Älta IF ", isCorrect: !0 },
        ],
      },
      {
        id: 26,
        question:
          "Det blev fyra fina år i LFC för Lina Hurtig, som 2020 lämnade klubben för spel i italienska Juventus. Där är hon lagkamrat med:",
        answers: [
          { answer: "Hanna Glas", isCorrect: !1 },
          { answer: "Linda Sembrant", isCorrect: !0 },
          { answer: "Sejde Abrahamsson", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question:
          "2016 vann den danska anfallaren Pernille Harder skytteligan i OBOS Damallsvenskan. Hur många mål gjorde hon för Linköping FC den säsongen?",
        answers: [
          { answer: "23 mål", isCorrect: !0 },
          { answer: "27 mål", isCorrect: !1 },
          { answer: "31 mål", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "Efter två härliga år har Ronja Aronsson nu gått vidare och lämnat klubben. Hur många tävlingsmatcher blev det totalt för Ronja i LFC?",
        answers: [
          { answer: "32 matcher", isCorrect: !1 },
          { answer: "39 matcher", isCorrect: !0 },
          { answer: "44 matcher", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "2016 var en fantastisk säsong, då LFC överlägset vann OBOS Damallsvenskan. Hur många mål gjorde den före detta LFC-spelaren Stina Blackstenius under säsongen?",
        answers: [
          { answer: "19 mål", isCorrect: !0 },
          { answer: "22 mål", isCorrect: !1 },
          { answer: "25 mål", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 9,
    team: "Piteå",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "Piteå IF DFF har sina rötter i Piteå IF, en idrottsförening med en lång historia. När grundades Piteå IF?",
        answers: [
          { answer: "1902", isCorrect: !1 },
          { answer: "1918", isCorrect: !0 },
          { answer: "1930", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "2021 var en tuff säsong för Piteå IF, som hamnade på en elfteplats i OBOS Damallsvenskan. Vad blev lagets slutpoäng?",
        answers: [
          { answer: "11 poäng", isCorrect: !1 },
          { answer: "13 poäng", isCorrect: !1 },
          { answer: "16 poäng", isCorrect: !0 },
        ],
      },
      {
        id: 2,
        question:
          "Vilken spelare gjorde flest mål för laget under 2021 med totalt 5 mål?",
        answers: [
          { answer: "Anam Imo", isCorrect: !0 },
          { answer: "Jennie Nordin", isCorrect: !1 },
          { answer: "Fanny Andersson", isCorrect: !1 },
        ],
      },
      {
        id: 3,
        question:
          "LF Arena är Piteå IF:s hemmaplan. Vad är det för underlag på arenan?",
        answers: [
          { answer: "Naturgräs", isCorrect: !1 },
          { answer: "Hybridgräs", isCorrect: !1 },
          { answer: "Konstgräs", isCorrect: !0 },
        ],
      },
      {
        id: 4,
        question:
          "2018 vann Piteå IF OBOS Damallsvenskan för första gången i klubbens historia. Julia Karlernäs stod för flest mål den säsongen med sina:",
        answers: [
          { answer: "11 mål", isCorrect: !0 },
          { answer: "13 mål", isCorrect: !1 },
          { answer: "16 mål", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Guldåret 2018 hade Piteå IF det högsta publiksnittet på hemmaplan av alla lag i OBOS Damallsvenskan, nämligen:",
        answers: [
          { answer: "Ca 1 000", isCorrect: !1 },
          { answer: "Ca 1 500", isCorrect: !1 },
          { answer: "Ca 2 000", isCorrect: !0 },
        ],
      },
      {
        id: 6,
        question:
          "Det var en nervkittlande sista omgång i OBOS Damallsvenskan 2018 innan det stod klart att Piteå IF tagit hem guldet. Vad blev matchresultatet i säsongens sista vinst mot Växjö DFF?",
        answers: [
          { answer: "5-2", isCorrect: !1 },
          { answer: "6-0", isCorrect: !1 },
          { answer: "6-1", isCorrect: !0 },
        ],
      },
      {
        id: 7,
        question:
          "2019 debuterade Piteå IF i Champions League för allra första gången. Vad heter det danska lag som klubben ställdes mot i det första mötet? ",
        answers: [
          { answer: "Brøndby IF", isCorrect: !0 },
          { answer: "HB Køge", isCorrect: !1 },
          { answer: "Fortuna Hjørring", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "Säsongen 2022 välkomnar PIF ett nytt ansikte i målburen: 23-åriga Amanda McGlynn. Var är hon född?",
        answers: [
          { answer: "Australien", isCorrect: !1 },
          { answer: "USA", isCorrect: !0 },
          { answer: "England", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          "Vilken var den enda spelaren i PIF att bli uttagen till U23-landslaget 2021?",
        answers: [
          { answer: "Selina Henriksson", isCorrect: !0 },
          { answer: "Astrid Larsson", isCorrect: !1 },
          { answer: "Hanna Andersson", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Efter bara en säsong i Piteå IF har backen Jennie Nordin valt att lämna laget för en annan klubb i OBOS Damallsvenskan. Nu spelar hon för:",
        answers: [
          { answer: "Linköpings FC", isCorrect: !1 },
          { answer: "AIK", isCorrect: !0 },
          { answer: "BK Häcken", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "Rutinerade Cecilia Edlund är en av truppens lagkaptener inför säsongen 2022. Vilket är hennes tröjnummer?",
        answers: [
          { answer: "Nr 8", isCorrect: !1 },
          { answer: "Nr 11", isCorrect: !0 },
          { answer: "Nr 15", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Vilken PIF-spelare vann pris för Årets Mål på galan Damallsvenskans Bästa 2021?",
        answers: [
          { answer: "Anam Imo", isCorrect: !1 },
          { answer: "Nichole Persson ", isCorrect: !1 },
          { answer: "Guro Pettersen", isCorrect: !0 },
        ],
      },
      {
        id: 13,
        question:
          "2021 vann målvakten Guro Pettersen priset Årets mål med sin frispark som gick rätt in i mål. Vilken motståndare mötte PIF i matchen? ",
        answers: [
          { answer: "Linköping FC", isCorrect: !0 },
          { answer: "Växjö DFF", isCorrect: !1 },
          { answer: " KIF Örebro DFF", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Under hemmapremiären 2022 hyllades mittfältaren Josefin Johansson för sin mångåriga insats i PIF, då det var hennes:",
        answers: [
          { answer: "150:e match", isCorrect: !1 },
          { answer: "200:e match", isCorrect: !0 },
          { answer: "300:e match", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question:
          "PIF avslutade Svenska Cupen 2021/2022 med den härliga vinsten 5-0. Vilket var motståndarlaget?",
        answers: [
          { answer: "Djurgården ", isCorrect: !1 },
          { answer: "Mallbackens IF", isCorrect: !0 },
          { answer: "Eskilstuna United", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "PIF-spelarna Katrina Guillou och Thea Bergsten har en gång i tiden spelat för samma klubb, nämligen:",
        answers: [
          { answer: "Morön BK", isCorrect: !0 },
          { answer: "Sunnanå SK", isCorrect: !1 },
          { answer: "Sundsvalls DFF", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Vad heter den före detta PIF-spelaren som lämnade laget 2017 för spel i USA, och därefter skrev kontrakt med Hammarby?",
        answers: [
          { answer: "Lotta Ökvist", isCorrect: !0 },
          { answer: "Madelen Janogy ", isCorrect: !1 },
          { answer: "Nina Jakobsson", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "Inför säsongen 2022 har PIF bland annat stärkt upp truppen med backen Alexandra Benediktsson. Vilken klubb lämnar hon?",
        answers: [
          { answer: "KIF Örebro DFF", isCorrect: !1 },
          { answer: "BP", isCorrect: !1 },
          { answer: "Vittsjö GIK", isCorrect: !0 },
        ],
      },
      {
        id: 19,
        question: "Vilket år startade Piteå IF sin damsektion? ",
        answers: [
          { answer: "1981", isCorrect: !1 },
          { answer: "1985", isCorrect: !0 },
          { answer: "1989", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "2021 var Fanny Andersson en av PIF-spelarna som spelade flest matcher från start, nämligen: ",
        answers: [
          { answer: "19 matcher", isCorrect: !1 },
          { answer: "22 matcher", isCorrect: !0 },
          { answer: "24 matcher", isCorrect: !1 },
        ],
      },
      {
        id: 21,
        question:
          "2021 var Linnea Sehlberg en av spelarna som tackade för sig och gick vidare mot nya äventyr. Hur många säsonger spelade hon totalt i PIF?",
        answers: [
          { answer: "4 säsonger", isCorrect: !1 },
          { answer: "5 säsonger", isCorrect: !0 },
          { answer: "6 säsonger", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "Vinnaråret 2018 är ett år vi mer än gärna tänker tillbaka på. Minns du vilket lag PIF mötte i den allra första omgången?",
        answers: [
          { answer: "IFK Kalmar", isCorrect: !0 },
          { answer: "Djurgården", isCorrect: !1 },
          { answer: "Eskilstuna United", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "Precis som Josefin Johansson har även Faith Ikidi spelat ett imponerande antal matcher i OBOS Damallsvenskan, nämligen över:",
        answers: [
          { answer: "200 matcher", isCorrect: !1 },
          { answer: "250 matcher", isCorrect: !1 },
          { answer: "300 matcher", isCorrect: !0 },
        ],
      },
      {
        id: 24,
        question:
          "Den 20:e juni 2021 var publiken äntligen tillbaka på LF Arena, dock med vissa restriktioner. Vilken motståndare mötte PIF då?",
        answers: [
          { answer: "Hammarby", isCorrect: !0 },
          { answer: "Kristianstads DFF", isCorrect: !1 },
          { answer: "Djurgården", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Hemmapremiären 2022 bjöd på solsken när laget mötte Kristianstads DFF. Vad blev matchresultatet?",
        answers: [
          { answer: "1-1", isCorrect: !0 },
          { answer: "Vinst för PIF med 1-0 ", isCorrect: !1 },
          { answer: "Vinst för Kristianstads DFF med 2-1 ", isCorrect: !1 },
        ],
      },
      {
        id: 26,
        question:
          "Det blev en tung bortaförlust för PIF när laget mötte Vittsjö GIK den 15 maj 2022. Vad heter den före detta PIF-spelaren som gjorde två mål för motståndarlaget?",
        answers: [
          { answer: "Clara Markstedt", isCorrect: !0 },
          { answer: "Fernanda Da Silva", isCorrect: !1 },
          { answer: "Lisa Klinga", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question:
          "Vilket lag ingick inte i grupp 3 tillsammans med PIF i Svenska Cupen 2022? ",
        answers: [
          { answer: "Eskilstuna United ", isCorrect: !1 },
          { answer: "Kristianstads DFF", isCorrect: !0 },
          { answer: "Mallbackens IF", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "I den första hemmavinsten för 2022 tog laget hem en 1-0-seger mot IFK Kalmar efter ett straffmål. Vilken spelare satte straffen?",
        answers: [
          { answer: "Katrina Guillou", isCorrect: !1 },
          { answer: "Anam Imo", isCorrect: !1 },
          { answer: "Hlin Eiriksdottir", isCorrect: !0 },
        ],
      },
      {
        id: 29,
        question:
          "Vilken PIF-spelare tilldelades Norrstjärnan 2021, priset för Norrbottens bästa fotbollsspelare?",
        answers: [
          { answer: "Selina Henriksson", isCorrect: !1 },
          { answer: "Fanny Andersson", isCorrect: !0 },
          { answer: "Johanna Nyman", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 10,
    team: "Rosengård",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "FC Rosengård är den mest framgångsrika klubben inom svensk damfotboll med hela 12 segrar i OBOS Damallsvenskan. Vilket år tar laget sitt första guld i finalen mot Sunnanå SK, då under namnet Malmö FF?",
        answers: [
          { answer: "1981", isCorrect: !1 },
          { answer: "1986", isCorrect: !0 },
          { answer: "1990", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "Laget har tidigare spelat under klubbnamnet Ldb FC Malmö. Vilket år byttes det till FC Rosengård?",
        answers: [
          { answer: "2011", isCorrect: !1 },
          { answer: "2013", isCorrect: !0 },
          { answer: "2015", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "FC Rosengård rankas som en av de bästa fotbollsklubbarna i Europa med sin nuvarande elfteplacering. Vilket lag toppar Uefas lista 2021/2022?",
        answers: [
          { answer: "FC Barcelona", isCorrect: !1 },
          { answer: "Paris Saint-Germain", isCorrect: !1 },
          { answer: "Olympique Lyonnais", isCorrect: !0 },
        ],
      },
      {
        id: 3,
        question:
          "Vad heter klubbens projekt som arbetar för att stärka unga tjejer genom att använda fotboll som verktyg?",
        answers: [
          { answer: "Football for Life", isCorrect: !0 },
          { answer: "Boost by FC Rosengård", isCorrect: !1 },
          { answer: "Hattrick by FC Rosengård", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "2020 vann den dåvarande FC Rosengård-spelaren Anna Anvegård skytteligan i OBOS Damallsvenskan. Hur många mål gjorde hon totalt för laget?",
        answers: [
          { answer: "16 mål", isCorrect: !0 },
          { answer: "19 mål", isCorrect: !1 },
          { answer: "23 mål", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          " Vilken spelare gjorde flest mål för laget säsongen 2021 med totalt 12 mål?",
        answers: [
          { answer: "Jelena Cankovic", isCorrect: !1 },
          { answer: "Sanne Troelsgaard", isCorrect: !1 },
          { answer: "Olivia Schough", isCorrect: !0 },
        ],
      },
      {
        id: 6,
        question:
          "Vilken spelare i laget vann pris för Årets Mittfältare på galan Damallsvenskans Bästa 2021?",
        answers: [
          { answer: "Caroline Seger", isCorrect: !1 },
          { answer: "Jelena Cankovic", isCorrect: !0 },
          { answer: "Olivia Welin", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question: "Hur många gånger har FC Rosengård vunnit Svenska Cupen?",
        answers: [
          { answer: "4 gånger", isCorrect: !1 },
          { answer: "5 gånger", isCorrect: !0 },
          { answer: "6 gånger", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "2005 vinner Therese Lundin skytteligan med sina 21 mål tillsammans med en annan stor profil. Vem?",
        answers: [
          { answer: "Marta Viera da Silva", isCorrect: !0 },
          { answer: "Laura Kalmari", isCorrect: !1 },
          { answer: "Victoria Sandell Svensson", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          "Vilket motståndarlag mötte FC Rosengård i lagets sista match i OBOS Damallsvenskan 2019, då man också vann guldet?",
        answers: [
          { answer: "Kristianstads DFF", isCorrect: !0 },
          { answer: "Växjö DFF", isCorrect: !1 },
          { answer: "Vittsjö GIK", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "Under 2021 var mittfältaren Olivia Lindstedt inte en del av truppen. Varför?",
        answers: [
          { answer: "Hon var skadad", isCorrect: !1 },
          { answer: "Hon bytte klubb", isCorrect: !1 },
          { answer: "Hon var utlånad i USA", isCorrect: !0 },
        ],
      },
      {
        id: 11,
        question:
          "2022 presenteras laget med en ny färg på hemmastället. Vilken?",
        answers: [
          { answer: "Helvitt", isCorrect: !1 },
          { answer: "Mörkrött", isCorrect: !1 },
          { answer: "Mörkblått", isCorrect: !0 },
        ],
      },
      {
        id: 12,
        question:
          "FC Rosengård är ett av de lag som lockar mest publik i OBOS Damallsvenskan. Vilket publiksnitt hemma hade laget under pandemiåret 2021?",
        answers: [
          { answer: "587 besökare", isCorrect: !0 },
          { answer: "765 besökare", isCorrect: !1 },
          { answer: "932 besökare", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Hösten 2021 missade laget gruppspelet i Champions League efter tung förlust mot den tyska storklubben: ",
        answers: [
          { answer: "Bayern München", isCorrect: !1 },
          { answer: "Hoffenheim", isCorrect: !0 },
          { answer: "Vfl Wolfsburg", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Mittfältaren Ria Öling är en central spelare FC Rosengård och är även bofast i:",
        answers: [
          { answer: "Finska landslaget", isCorrect: !0 },
          { answer: "Danska landslaget", isCorrect: !1 },
          { answer: "Svenska landslaget", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question:
          "Vilken spelare stod för ett hattrick när laget besegrade Djurgården med 5-2 i OBOS Damallsvenskans tredje omgång 2022?",
        answers: [
          { answer: "Olivia Schough", isCorrect: !1 },
          { answer: "Loreta Kullashi ", isCorrect: !1 },
          { answer: "Mia Persson", isCorrect: !0 },
        ],
      },
      {
        id: 16,
        question:
          "Danska Sofie Bredgaard är ett av klubbens nyförvärv inför säsongen 2022. Hon kommer senast från spel i:",
        answers: [
          { answer: "Umeå IK", isCorrect: !1 },
          { answer: "Linköping FC", isCorrect: !0 },
          { answer: "HB Køge", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Den 26:e maj 2022 spelade FC Rosengård final i Svenska Cupen. Vilket var motståndarlaget?",
        answers: [
          { answer: "BK Häcken", isCorrect: !0 },
          { answer: "Eskilstuna United", isCorrect: !1 },
          { answer: " Hammarby", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "2021 utsågs Glódís Perla Viggósdóttir till en av OBOS Damallsvenskans bästa spelare av Fotbollskanalen. Hon har nu lämnat FC Rosengård för den tyska storklubben:",
        answers: [
          { answer: "Bayern München", isCorrect: !0 },
          { answer: "Werder Bremen ", isCorrect: !1 },
          { answer: "FFC Turbine Potsdam", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "2020 återvände försvararen Emma Berglund hem till FC Rosengård. Vilket är hennes tröjnummer i truppen?",
        answers: [
          { answer: "Nr 15", isCorrect: !1 },
          { answer: "Nr 25", isCorrect: !0 },
          { answer: "Nr 28", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "Sedan flytten från Djurgården har Olivia Schough dundrat in mål för FC Rosengård. Vilken är hennes moderklubb?",
        answers: [
          { answer: "Ullareds IK", isCorrect: !1 },
          { answer: "Falkenbergs FF", isCorrect: !1 },
          { answer: "Torups/Rydö FF", isCorrect: !0 },
        ],
      },
      {
        id: 21,
        question:
          "FC Rosengård-legenden Therese Sjögran är en av Europas mesta landslagsspelare. Hur många matcher har hon totalt spelat i blågult?",
        answers: [
          { answer: "186 matcher ", isCorrect: !1 },
          { answer: "214 matcher", isCorrect: !0 },
          { answer: "235 matcher", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "2021 blev Amanda Ilestedt klar för Paris Saint-Germain efter spel i Bayern München. Vilket år lämnade hon FC Rosengård? ",
        answers: [
          { answer: "2016", isCorrect: !1 },
          { answer: "2017", isCorrect: !0 },
          { answer: "2018", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "Matilda Kristell är en av spelarna som 2022 lånas ut till ett annat lag, nämligen danska:",
        answers: [
          { answer: "Brøndby", isCorrect: !0 },
          { answer: "Fortuna Hjørring", isCorrect: !1 },
          { answer: "HB Køge", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "2022 flyttar FC Rosengård upp Somea Polozen till A-laget. Vilken är hennes position?",
        answers: [
          { answer: "Målvakt", isCorrect: !0 },
          { answer: "Back", isCorrect: !1 },
          { answer: "Mittfältare", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Vilket mobilspel spelar målvakten Angel Makusa alltid före match?",
        answers: [
          { answer: "Candy Crush", isCorrect: !1 },
          { answer: "Animal Crossing", isCorrect: !1 },
          { answer: "Geometry Dash", isCorrect: !0 },
        ],
      },
      {
        id: 26,
        question: "Vem är Jessica Wiks största förebild inom fotbollen?",
        answers: [
          { answer: "Zlatan", isCorrect: !1 },
          { answer: "Sergio Ramos", isCorrect: !0 },
          { answer: "Lionel Messi", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Vilken spelare drog på sig flest gula kort (4 stycken) under OBOS Damallsvenskan 2021?",
        answers: [
          { answer: "Sanne Troelsgaard", isCorrect: !0 },
          { answer: "Loreta Kullashi", isCorrect: !1 },
          { answer: "Fiona Brown", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 11,
    team: "Umeå IK",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "Vilket år spelade UIK för första gången i OBOS Damallsvenskan?",
        answers: [
          { answer: "1994", isCorrect: !1 },
          { answer: "1996", isCorrect: !0 },
          { answer: "1998", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "2022 är UIK tillbaka i OBOS Damallsvenskan efter avancemang från Elitettan. Vad blev lagets totalpoäng 2021?",
        answers: [
          { answer: "59 poäng", isCorrect: !1 },
          { answer: "62 poäng", isCorrect: !1 },
          { answer: "66 poäng", isCorrect: !0 },
        ],
      },
      {
        id: 2,
        question:
          "Vilken spelare vann skytteligan i Elitettan 2021 med sina 22 mål för UIK?",
        answers: [
          { answer: "Henna-Riikka Honkanen", isCorrect: !0 },
          { answer: "Monica Jusu Bah", isCorrect: !1 },
          { answer: "Lisa Björk", isCorrect: !1 },
        ],
      },
      {
        id: 3,
        question:
          "Vem i UIK spelade flest matcher från start under föregående säsong i Elitettan med totalt 26 matcher?",
        answers: [
          { answer: "Rosa Herreros Ossorio", isCorrect: !1 },
          { answer: "Sarah Mellouk", isCorrect: !1 },
          { answer: "Vilma Koivisto", isCorrect: !0 },
        ],
      },
      {
        id: 4,
        question: "Hur många gånger har UIK vunnit OBOS Damallsvenskan?",
        answers: [
          { answer: "6 gånger", isCorrect: !1 },
          { answer: "7 gånger", isCorrect: !0 },
          { answer: "8 gånger", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "2002 gick Hanna Ljungberg till historien som tidernas största skyttedrottning i OBOS Damallsvenskan. Hur många mål gjorde hon totalt för UIK den säsongen? ",
        answers: [
          { answer: "32 mål ", isCorrect: !1 },
          { answer: "36 mål ", isCorrect: !1 },
          { answer: "39 mål", isCorrect: !0 },
        ],
      },
      {
        id: 6,
        question:
          "Umeå Energi Arena är UIK:s hemmaarena. Fotbollsstadion är den största i Umeå, med en publikkapacitet på:",
        answers: [
          { answer: "6 000 personer", isCorrect: !0 },
          { answer: "6 200 personer", isCorrect: !1 },
          { answer: "6 500 personer", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Marta Vieira da Silva är en av UIK:s främsta målgörare genom tiderna. Vilket år lämnade hon laget?",
        answers: [
          { answer: "2007", isCorrect: !1 },
          { answer: "2008", isCorrect: !0 },
          { answer: "2009", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "UIK-profilen Frida Östberg är en av de stjärnor som belönats med Diamantbollen, priset för årets främsta fotbollsspelare. Efter fotbollskarriären har Frida arbetat som:",
        answers: [
          { answer: "Tränare för U23-landslaget", isCorrect: !1 },
          { answer: "Expertkommentator", isCorrect: !0 },
          { answer: "Talangscout", isCorrect: !1 },
        ],
      },
      {
        id: 9,
        question:
          " 2008 kammade UIK hem guldet i OBOS Damallsvenskan med en förkrossande vinst mot Bälinge. Vad blev matchresultatet? ",
        answers: [
          { answer: "8-1", isCorrect: !1 },
          { answer: "10-0", isCorrect: !1 },
          { answer: "11-1", isCorrect: !0 },
        ],
      },
      {
        id: 10,
        question:
          " 2021 slogs nytt publikrekord i OBOS Damallsvenskan, när 18 537 supportrar såg derbyt mellan Hammarby och AIK. Vilket var det tidigare rekordet från 2008, då UIK besegrade Linköping FC på Folkungavallen?",
        answers: [
          { answer: "9 413 ", isCorrect: !0 },
          { answer: "9 752", isCorrect: !1 },
          { answer: "9 968", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question: "Vilket år vann UIK OBOS Damallsvenskan för första gången?",
        answers: [
          { answer: "1999", isCorrect: !1 },
          { answer: "2001", isCorrect: !1 },
          { answer: "2001", isCorrect: !0 },
        ],
      },
      {
        id: 12,
        question:
          "Vilket lag besegrade UIK med 4-2 i lagets sista match i Elitettan 2021? ",
        answers: [
          { answer: "Mallbackens IF Sunne ", isCorrect: !0 },
          { answer: "Bollstanäs SK ", isCorrect: !1 },
          { answer: "Morön BK", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Vinsten mot Vittsjö GIK med 4-0 var lagets första seger säsongen 2022. Vilken spelare satte straffen i den 63:e matchminuten?",
        answers: [
          { answer: "Henna-Riika Honkanen", isCorrect: !1 },
          { answer: "Sara Mellouk", isCorrect: !0 },
          { answer: "Lisa Björk", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Målvakten Erin Nayler är ett av lagets nyförvärv inför säsongen 2022. Hon har tidigare spelat i landslaget för:",
        answers: [
          { answer: "USA", isCorrect: !1 },
          { answer: " Australien", isCorrect: !1 },
          { answer: "Nya Zeeland", isCorrect: !0 },
        ],
      },
      {
        id: 15,
        question:
          "Rutinerade Johanna Nyman är inte med i UIK:s trupp 2022. Varför? ",
        answers: [
          { answer: "Hon har avslutat sin fotbollskarriär ", isCorrect: !0 },
          { answer: "Hon är skadad", isCorrect: !1 },
          { answer: "Hon är utlånad till en annan klubb", isCorrect: !1 },
        ],
      },
      {
        id: 16,
        question:
          "Rosa Herreros följde inte med UIK när laget avancerade till OBOS Damallsvenskan 2022. Vilken klubb i Elitettan tillhör hon nu?",
        answers: [
          { answer: "Växjö DFF", isCorrect: !1 },
          { answer: "IK Uppsala", isCorrect: !0 },
          { answer: "Sundsvalls DFF", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "2022 återvänder Briana Campos till UIK efter spel i Vittsjö GIK. Briana spelar också för damlandslaget i:",
        answers: [
          { answer: "USA", isCorrect: !1 },
          { answer: "Mexiko", isCorrect: !0 },
          { answer: "Costa Rica", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "Vilket lag mötte UIK i premiären på hemmaplan, där snökaos ställde till det rejält för spelarna?",
        answers: [
          { answer: "BK Häcken", isCorrect: !0 },
          { answer: "IF Brommapojkarna", isCorrect: !1 },
          { answer: "Vittsjö GIK", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Vem fick utmärkelsen Månadens Spelare i Elitettan för augusti månad 2021? ",
        answers: [
          { answer: "Henna-Riikka Honkanen", isCorrect: !1 },
          { answer: "Glory Ogbonna", isCorrect: !1 },
          { answer: "Monica Jusu Bah", isCorrect: !0 },
        ],
      },
      {
        id: 20,
        question:
          "Det blev ett trist avslut för Umeå IK i Svenska Cupen 2022, som slogs ut i gruppfinalen av: ",
        answers: [
          { answer: "Hammarby", isCorrect: !0 },
          { answer: " Bollstanäs SK ", isCorrect: !1 },
          { answer: "BP", isCorrect: !1 },
        ],
      },
      {
        id: 21,
        question:
          "Vilken UIK-spelare är uttagen för spel i svenska U23-landslaget 2022? ",
        answers: [
          { answer: "Lisa Björk ", isCorrect: !1 },
          { answer: "Tilde Johansson", isCorrect: !1 },
          { answer: "Wilma Carlsson", isCorrect: !0 },
        ],
      },
      {
        id: 22,
        question:
          "2022 välkomnar UIK nyförvärvet Katariina Kosola till laget. Hur gammal var Katariina när hon började spela fotboll?",
        answers: [
          { answer: "6 år gammal", isCorrect: !0 },
          { answer: "8 år gammal", isCorrect: !1 },
          { answer: "10 år gammal", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "2021 skänkte entreprenören Edgar Sjölund en stor donation till föreningen. Hur stor var summan? ",
        answers: [
          { answer: "1 miljon kronor", isCorrect: !1 },
          { answer: "2 miljoner kronor", isCorrect: !0 },
          { answer: "3 miljoner kronor", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Ytterbacken Anni Miettunen är nu klar för spel i UIK. Hon kommer närmast från spel i: ",
        answers: [
          { answer: "FC Honka", isCorrect: !1 },
          { answer: "HJK", isCorrect: !1 },
          { answer: "Åland United ", isCorrect: !0 },
        ],
      },
      {
        id: 25,
        question:
          "Vilken spelare utsågs till Årets Rookie 2021 på Umeå Idrottsgala? ",
        answers: [
          { answer: "Lisa Björk", isCorrect: !0 },
          { answer: "Elvira Fjällström", isCorrect: !1 },
          { answer: "Villemo Dahlqvist", isCorrect: !1 },
        ],
      },
      {
        id: 26,
        question:
          "Den unga talangen Tuva Skoog har redan hunnit med tre säsonger i Elitettan och är nu klar för spel i UIK. Var är hon uppvuxen?",
        answers: [
          { answer: "Dorotea", isCorrect: !1 },
          { answer: "Skellefteå", isCorrect: !0 },
          { answer: "Umeå", isCorrect: !1 },
        ],
      },
      {
        id: 27,
        question: "Glory Ogbonna har tyvärr lämnat laget inför 2022. Varför? ",
        answers: [
          { answer: "På grund av hälsoskäl", isCorrect: !0 },
          { answer: "På grund av skador", isCorrect: !1 },
          { answer: "På grund av byte till annan klubb", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "Umeå Energi Arena är UIK:s hemmaarena. Vad är det för underlag på arenan? ",
        answers: [
          { answer: "Naturgräs ", isCorrect: !1 },
          { answer: "Hybridgräs ", isCorrect: !1 },
          { answer: "Konstgräs", isCorrect: !0 },
        ],
      },
      {
        id: 29,
        question:
          "Umeå IK har vunnit OBOS Damallsvenskan 7 gånger. Hur många gånger har laget hamnat på en andraplats i ligan – och vunnit silver?",
        answers: [
          { answer: "3 gånger ", isCorrect: !1 },
          { answer: "4 gånger ", isCorrect: !0 },
          { answer: "6 gånger ", isCorrect: !1 },
        ],
      },
    ],
  },
  {
    id: 12,
    team: "Vittsjö",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "Vittsjö GIK är en idrottsklubb med anor från 1930-talet. När bildades ett flicklag i föreningen för första gången?",
        answers: [
          { answer: "1978", isCorrect: !1 },
          { answer: "1980", isCorrect: !0 },
          { answer: "1983", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "När laget gick upp i OBOS Damallsvenskan för första gången skrev Vittsjö GIK historia som det enda allsvenska laget från Hässleholm. Vilket år var detta?",
        answers: [
          { answer: "2010", isCorrect: !1 },
          { answer: "2012", isCorrect: !0 },
          { answer: "2014", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "Det blev en historisk seger för laget i den första damallsvenska hemmamatchen. Vilken motståndare mötte laget på Vittsjö Idrottspark?",
        answers: [
          { answer: "AIK", isCorrect: !0 },
          { answer: "Umeå IK", isCorrect: !1 },
          { answer: "Tyresö FF", isCorrect: !1 },
        ],
      },
      {
        id: 3,
        question:
          "Fotbollsprofilen Lisa Klinga spelade 2014 i damernas Champions League-final mot tyska Wolfsburg. Då tillhörde hon inte Vittsjö GIK, utan spelade för:",
        answers: [
          { answer: "Tyresö FF", isCorrect: !0 },
          { answer: "Linköping FC", isCorrect: !1 },
          { answer: "Piteå IF", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "Vittsjö IP är hemmaarena för Vittsjö GIK. Vad är det för underlag på idrottsplatsen?",
        answers: [
          { answer: "Naturgräs", isCorrect: !0 },
          { answer: "Hybridgräs", isCorrect: !1 },
          { answer: "Konstgräs", isCorrect: !1 },
        ],
      },
      {
        id: 5,
        question:
          "Vilken offentlig person är en känd anhängare av Vittsjö GIK?",
        answers: [
          { answer: "Fredrik Ljungberg", isCorrect: !1 },
          { answer: "Nilla Fischer", isCorrect: !1 },
          { answer: "Gustav Fridolin", isCorrect: !0 },
        ],
      },
      {
        id: 6,
        question:
          "Vilken spelare gjorde flest mål för laget säsongen 2021, med totalt 7 mål?",
        answers: [
          { answer: "Clara Markstedt", isCorrect: !1 },
          { answer: "Fernanda Da Silva", isCorrect: !0 },
          { answer: "Emily Gielnik", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Skyttedrottningen Fernanda Da Silva har nu sagt farväl av Hässleholm och återvänt hem till Brasilien. Vad heter hennes nya klubb?",
        answers: [
          { answer: "Palmeiras", isCorrect: !1 },
          { answer: "Corinthians", isCorrect: !1 },
          { answer: "Santos FC", isCorrect: !0 },
        ],
      },
      {
        id: 8,
        question:
          "Vilken av följande Vittsjö GIK-spelare drog på sig flest gula kort (3 stycken) under säsongen 2021?",
        answers: [
          { answer: "Lisa Klinga", isCorrect: !1 },
          { answer: "Briana Campos", isCorrect: !1 },
          { answer: "Nellie Persson", isCorrect: !0 },
        ],
      },
      {
        id: 9,
        question:
          "Mittfältaren Briana Campos har nu lämnat Vittsjö GIK för spel i Umeå IK. Briana spelar också för damlandslaget i:",
        answers: [
          { answer: "USA", isCorrect: !1 },
          { answer: "Mexiko", isCorrect: !0 },
          { answer: "Costa Rica", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question:
          "En av spelarna nedan spelade flest matcher från start för Vittsjö GIK med totalt 22 matcher. Vem?",
        answers: [
          { answer: "Ebba Hed", isCorrect: !0 },
          { answer: "Sandra Adolfsson", isCorrect: !1 },
          { answer: "Mie Leth Jans", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "Hösten 2021 lånades anfallaren Paulina Nyström ut till ett annat lag. Nu står det klart att övergången blivit permanent. Vad heter laget?",
        answers: [
          { answer: "Eskilstuna United", isCorrect: !0 },
          { answer: "BP", isCorrect: !1 },
          { answer: "Djurgården", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Säsongen 2021 i OBOS Damallsvenskan landade Vittsjö GIK på en femteplacering i tabellen. Vad blev lagets totalpoäng?",
        answers: [
          { answer: "31 poäng", isCorrect: !1 },
          { answer: "32 poäng", isCorrect: !0 },
          { answer: "35 poäng", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Vilket av klubbens nyförvärv korades till Årets Genombrott i Elitettan 2021?",
        answers: [
          { answer: "Jutta Rantala", isCorrect: !1 },
          { answer: "Hanna Ekengren", isCorrect: !1 },
          { answer: "Nova Karlsson", isCorrect: !0 },
        ],
      },
      {
        id: 14,
        question:
          "Linda Sällström är tillbaka i Vittsjö efter spel i både Paris och Helsingfors. Linda är tidernas största målskytt i finska damlandslaget – hur många mål hade hon totalt gjort för laget 2021?",
        answers: [
          { answer: "45 mål", isCorrect: !1 },
          { answer: "50 mål", isCorrect: !0 },
          { answer: "55 mål", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question:
          "Sarah Stratigakis är ett av klubbens nyförvärv inför säsongen 2022. Hon kommer senast från Michigan Wolverines, och har även spelat flertalet landskamper för:",
        answers: [
          { answer: "Nya Zeeland", isCorrect: !1 },
          { answer: "USA", isCorrect: !1 },
          { answer: "Kanada", isCorrect: !0 },
        ],
      },
      {
        id: 16,
        question:
          "När Fotbollskanalen utsåg årets 25 bästa spelare i OBOS Damallsvenskan 2021 hamnade Vittsjös Sabrina D’Angelo på plats 25 för sin starka säsong i målet. Vilket tröjnummer har hon?",
        answers: [
          { answer: "Nr 1", isCorrect: !1 },
          { answer: "Nr 8", isCorrect: !1 },
          { answer: "Nr 13", isCorrect: !0 },
        ],
      },
      {
        id: 17,
        question:
          "Många lag i OBOS Damallsvenskan kämpar med att locka publik till sina matcher. Vad var Vittsjö GIK:s publiksnitt hemma under pandemiåret 2021?",
        answers: [
          { answer: "195", isCorrect: !1 },
          { answer: "283", isCorrect: !0 },
          { answer: "325", isCorrect: !1 },
        ],
      },
      {
        id: 18,
        question:
          "Inför säsongen 2022 lämnade både Tove Almqvist och Ebba Wieder laget. Nu spelar de sida vid sida i:",
        answers: [
          { answer: "Djurgården", isCorrect: !0 },
          { answer: "Hammarby", isCorrect: !1 },
          { answer: "Kristianstads DFF", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Laget har nu fått säga farväl av Ebba Hed, som lämnat Sveriges gränser för spanska Madrid CFF. Hur många säsonger spelade Ebba i Vittsjötröjan?",
        answers: [
          { answer: "5 säsonger", isCorrect: !1 },
          { answer: "6 säsonger", isCorrect: !1 },
          { answer: "7 säsonger", isCorrect: !0 },
        ],
      },
      {
        id: 20,
        question:
          "Katrina Gorry har rest hela vägen från Australien för att spela i Vittsjö GIK. Vad blev helt avgörande för att hon skulle skriva på kontraktet?",
        answers: [
          {
            answer:
              "Att få spela ihop med landslagskamraten Clare Polkinghorne",
            isCorrect: !1,
          },
          { answer: "En rekordhög lön", isCorrect: !1 },
          {
            answer: "En frivillig pensionärspool för dottern Harper",
            isCorrect: !0,
          },
        ],
      },
      {
        id: 21,
        question:
          "Vi säger välkommen till Ulf Kristiansson, ny tränare i Vittsjö GIK. Ulf har tidigare arbetat som matchanalytiker för en av Afrikas största fotbollsnationer, nämligen:",
        answers: [
          { answer: "Kamerun", isCorrect: !1 },
          { answer: "Nigeria", isCorrect: !0 },
          { answer: "Senegal", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "2019 knep Vittsjö GIK tredjeplatsen i OBOS Damallsvenskan, lagets hittills bästa placering. Vilket lag var inte nytt för den säsongen?",
        answers: [
          { answer: "Kungsbacka DFF", isCorrect: !1 },
          { answer: "Limhamn Bunkeflo", isCorrect: !0 },
          { answer: "KIF Örebro", isCorrect: !1 },
        ],
      },
      {
        id: 23,
        question:
          "2021 besegrades Vittsjö GIK av Jitex BK i Svenska Cupens tredjeomgång. Det var inte första gången som Mölndalslaget slagit ut Vittsjö ur turneringen. Vad blev matchresultatet den här gången?",
        answers: [
          { answer: "3-1", isCorrect: !1 },
          { answer: "3-0", isCorrect: !1 },
          { answer: "2-1", isCorrect: !0 },
        ],
      },
      {
        id: 24,
        question:
          "Mittbacken Clare Polkinghorne har tidigare spelat för norska klubben Avaldsnes, precis som en annan spelare i laget. Vem?",
        answers: [
          { answer: "Lisa Klinga", isCorrect: !0 },
          { answer: "Sandra Adolfsson", isCorrect: !1 },
          { answer: "Clara Markstedt", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Nyförvärvet Kayla Adamek, som fått tröjnummer 17 i laget, har spelat flera matcher på Champions League-nivå. Kayla kommer närmast från en klubb i:",
        answers: [
          { answer: "Spanien", isCorrect: !0 },
          { answer: "Serbien", isCorrect: !1 },
          { answer: "Tyskland", isCorrect: !1 },
        ],
      },
      {
        id: 26,
        question:
          "Inför säsongen 2022 har Vittsjö GIK värvat anfallaren Hanna Ekengren från Djurgården. Vilket landslag nedan har Hanna inte spelat för?",
        answers: [
          { answer: "U17-landslaget", isCorrect: !1 },
          { answer: "U19-landslaget", isCorrect: !1 },
          { answer: "U23-landslaget", isCorrect: !0 },
        ],
      },
      {
        id: 27,
        question:
          "Mie Leth Jans är tillbaka från spel i Australien och har nu återvänt till laget. Vilken säsong var hennes första i Vittsjö GIK?",
        answers: [
          { answer: "2018", isCorrect: !1 },
          { answer: "2019", isCorrect: !1 },
          { answer: "2020", isCorrect: !0 },
        ],
      },
      {
        id: 28,
        question:
          "Anfallaren Moa Karlsson förlänger sitt kontrakt med Vittsjö GIK inför säsongen 2022. Hur många gånger har Moa vunnit skytteligan i division 1?",
        answers: [
          { answer: "En gång", isCorrect: !1 },
          { answer: "Två gånger", isCorrect: !0 },
          { answer: "Moa har aldrig vunnit skytteligan", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Trots en total kross av Örebro i säsongens sista match 2021 räckte det inte hela vägen för spel i Champions League. Vad blev matchresultatet?",
        answers: [
          { answer: "7-2", isCorrect: !1 },
          { answer: "8-1", isCorrect: !1 },
          { answer: "8-2", isCorrect: !0 },
        ],
      },
    ],
  },
  {
    id: 13,
    team: "Örebro",
    questionsAndAnswers: [
      {
        id: 0,
        question:
          "KIF Örebro DFF hette tidigare Karlslunds FF, som bildades redan 1920. Vilket år bildades klubbens damlag i fotboll?",
        answers: [
          { answer: "1978", isCorrect: !1 },
          { answer: "1980", isCorrect: !0 },
          { answer: "1983", isCorrect: !1 },
        ],
      },
      {
        id: 1,
        question:
          "2014 knep Örebro sin hittills bästa placering i OBOS Damallsvenskan med en andraplats. Vad blev lagets slutpoäng det året?",
        answers: [
          { answer: "42 poäng", isCorrect: !0 },
          { answer: "46 poäng", isCorrect: !1 },
          { answer: "49 poäng", isCorrect: !1 },
        ],
      },
      {
        id: 2,
        question:
          "2010 vann laget Svenska Cupen när Djurgården besegrades på bortaplan med:",
        answers: [
          { answer: "3-1", isCorrect: !1 },
          { answer: "4-0", isCorrect: !1 },
          { answer: "4-1", isCorrect: !0 },
        ],
      },
      {
        id: 3,
        question:
          "2015 tog drömmen om Champions League slut i åttondelsfinalen, trots att KIF Örebro spelade oavgjort i båda matcherna. Vilket var motståndarlaget?",
        answers: [
          { answer: "Paris Saint-Germain", isCorrect: !0 },
          { answer: "Lyon", isCorrect: !1 },
          { answer: "Paris FC", isCorrect: !1 },
        ],
      },
      {
        id: 4,
        question:
          "Åttondelen i Champions League blev den allra sista för Elin Magnusson, som då spelat över 400 matcher för KIF Örebro med totalt:",
        answers: [
          { answer: "15 säsonger", isCorrect: !1 },
          { answer: "16 säsonger", isCorrect: !1 },
          { answer: "17 säsonger", isCorrect: !0 },
        ],
      },
      {
        id: 5,
        question:
          "Trots klubbens överklagan blev Jenna Hellstrom avstängd i totalt fem matcher i fjol. Vilken motståndare mötte laget då Jenna blev utvisad?",
        answers: [
          { answer: "BK Häcken", isCorrect: !0 },
          { answer: "Växjö DFF", isCorrect: !1 },
          { answer: "Piteå IFF", isCorrect: !1 },
        ],
      },
      {
        id: 6,
        question:
          "Vem av tjejerna nedan spelade flest matcher från start för KIF Örebro under säsongen 2021?",
        answers: [
          { answer: "Frida Abrahamsson", isCorrect: !1 },
          { answer: "Nathalie Hoff Persson", isCorrect: !0 },
          { answer: "Elli Pikkujämsä", isCorrect: !1 },
        ],
      },
      {
        id: 7,
        question:
          "Behrn Arena, som tidigare hette Eyravallen, är hemmaplan för KIF Örebro. Vilket år invigdes den?",
        answers: [
          { answer: "1919", isCorrect: !1 },
          { answer: "1923", isCorrect: !0 },
          { answer: "1925", isCorrect: !1 },
        ],
      },
      {
        id: 8,
        question:
          "2005 klev Pia Sundhage in som tränare för KIF Örebro innan hon gick vidare som assisterande förbundskapten för kinesiska landslaget. För vilket landslag är hon förbundskapten nu?",
        answers: [
          { answer: "Sverige", isCorrect: !1 },
          { answer: "USA", isCorrect: !1 },
          { answer: "Brasilien", isCorrect: !0 },
        ],
      },
      {
        id: 9,
        question:
          "Pandemiåret 2021 hamnade KIF Örebro på en sjundeplats över lagen som lockade mest publik i OBOS Damallsvenskan. Vad var lagets publiksnitt hemma?",
        answers: [
          { answer: "334", isCorrect: !0 },
          { answer: "416", isCorrect: !1 },
          { answer: "502", isCorrect: !1 },
        ],
      },
      {
        id: 10,
        question: "Vilken spelare blev 2022 uttagen för spel i U23-landslaget?",
        answers: [
          { answer: "Wilma Öhman", isCorrect: !0 },
          { answer: "Elizabeth Pechersky", isCorrect: !1 },
          { answer: "Emilia Pelgander", isCorrect: !1 },
        ],
      },
      {
        id: 11,
        question:
          "Tillsammans med Moa Öhman vaktar Tove Enblom målet för KIF Örebro. Hur lång är Tove?",
        answers: [
          { answer: "177 cm", isCorrect: !1 },
          { answer: "180 cm", isCorrect: !0 },
          { answer: "183 cm", isCorrect: !1 },
        ],
      },
      {
        id: 12,
        question:
          "Den finska försvararen Elli Pikkujämsä är inte bara skicklig på fotbollsplanen. Som junior tog hon VM-medaljer i kategorin:",
        answers: [
          { answer: "Offpist", isCorrect: !1 },
          { answer: "Snowboard", isCorrect: !0 },
          { answer: "Längdskidor", isCorrect: !1 },
        ],
      },
      {
        id: 13,
        question:
          "Under fjolårets hemmamatch mot Linköping FC lyckades en spelare sätta in ett mål i den 92:a spelminuten – och därmed säkra tre poäng för laget. Vem?",
        answers: [
          { answer: "Jessie Scarpa", isCorrect: !0 },
          { answer: "Amanda Rantanen", isCorrect: !1 },
          { answer: "Karin Lundin", isCorrect: !1 },
        ],
      },
      {
        id: 14,
        question:
          "Kristine Lilly är en av världens mest meriterade spelare, med över 350 landslagskamper för USA:s landslag. Vilket år spelade hon för KIF Örebro, men bara en halv säsong?",
        answers: [
          { answer: "2004", isCorrect: !1 },
          { answer: "2005", isCorrect: !0 },
          { answer: "2006", isCorrect: !1 },
        ],
      },
      {
        id: 15,
        question:
          "2006 blev Stina Segerström klubbens första spelare i det svenska damlandslaget, tillsammans med:",
        answers: [
          { answer: "Lisa Dahlkvist", isCorrect: !1 },
          { answer: "Marie Hammarström", isCorrect: !1 },
          { answer: "Caroline Näfver", isCorrect: !0 },
        ],
      },
      {
        id: 16,
        question:
          "Fotbollsproffset Marie Hammarström spelade i KIF Örebro 2005-2012. Maries tvillingsyster Kristin har även hon haft en fotbollskarriär, som:",
        answers: [
          { answer: "Målvakt", isCorrect: !0 },
          { answer: "Mittfältare", isCorrect: !1 },
          { answer: "Back", isCorrect: !1 },
        ],
      },
      {
        id: 17,
        question:
          "Målvakten Cecilía Rán Rúnarsdóttir är just nu utlånad för spel i den europeiska storklubben:",
        answers: [
          { answer: "Vfl Wolfsburg", isCorrect: !1 },
          { answer: "Everton LFC", isCorrect: !1 },
          { answer: "Bayern München", isCorrect: !0 },
        ],
      },
      {
        id: 18,
        question: "",
        answers: [
          {
            answer:
              "Förutom Sophia Redenstrand och Olivia Mattsson värvade Växjö DFF ytterligare en spelare från KIF Örebro inför säsongen 2022. Vem?",
            isCorrect: !0,
          },
          { answer: "Lisa Davidsson", isCorrect: !1 },
          { answer: "Sara Lilja Vidlund", isCorrect: !1 },
        ],
      },
      {
        id: 19,
        question:
          "Mittbacken Michaela Kovacs är ett av lagets nyförvärv inför säsongen 2022. Var är Michaela född?",
        answers: [
          { answer: "Kanada", isCorrect: !1 },
          { answer: "USA", isCorrect: !0 },
          { answer: "Shetlandsöarna", isCorrect: !1 },
        ],
      },
      {
        id: 20,
        question:
          "Mittfältaren Katie Lockwood är ett lagets nya ansikten inför säsongen 2022. Hon kommer senast från spel i Champions League och klubben:",
        answers: [
          { answer: "PAOK", isCorrect: !1 },
          { answer: "Split", isCorrect: !1 },
          { answer: "Apollon Limassol", isCorrect: !0 },
        ],
      },
      {
        id: 21,
        question:
          "I fjol skadade Cassandra Larsson korsbandet och missade sin debutsäsong i klubben. Vad har Cassandra för tröjnummer i truppen?",
        answers: [
          { answer: "Nr 12", isCorrect: !0 },
          { answer: "Nr 19", isCorrect: !1 },
          { answer: "Nr 22", isCorrect: !1 },
        ],
      },
      {
        id: 22,
        question:
          "I slutet av februari var det så dags för gruppspelet i Svenska Cupen att dra igång. Vilket lag ingick inte i grupp två tillsammans med KIF Örebro?",
        answers: [
          { answer: "Eskilstuna United", isCorrect: !1 },
          { answer: "Jitex BK", isCorrect: !1 },
          { answer: "Kristianstads DFF", isCorrect: !0 },
        ],
      },
      {
        id: 23,
        question:
          "Inför säsongen 2022 har mittfältstalangen Rebecka Mannström lånats ut till ett lag i Elitettan, nämligen:",
        answers: [
          { answer: "Mallbackens IF Sunne", isCorrect: !1 },
          { answer: "Rävåsens IK", isCorrect: !0 },
          { answer: "Växjö DFF", isCorrect: !1 },
        ],
      },
      {
        id: 24,
        question:
          "Det blev en härlig 2-0-vinst för spelarna i lagets första hemmamatch för säsongen 2022. Vilket var motståndarlaget?",
        answers: [
          { answer: "Djurgården", isCorrect: !0 },
          { answer: "BP", isCorrect: !1 },
          { answer: "Umeå IK", isCorrect: !1 },
        ],
      },
      {
        id: 25,
        question:
          "Hur många spelare i truppen blev 2022 kallade för spel i finska damlandslaget?",
        answers: [
          { answer: "En spelare, Amanda Rantanen", isCorrect: !1 },
          {
            answer: "Två spelare, Heidi Kollanen och Amanda Rantanten",
            isCorrect: !1,
          },
          {
            answer:
              "Tre spelare, Elli Pikkujämsä, Heidi Kollanen och Amanda Rantanen",
            isCorrect: !0,
          },
        ],
      },
      {
        id: 26,
        question:
          "Anfallaren Wilma Öhman har tidigare spelat för Umeå IK. Det har en annan lagkamrat i truppen också gjort, nämligen:",
        answers: [
          { answer: "Moa Öhman", isCorrect: !1 },
          { answer: "Frida Abrahamsson", isCorrect: !1 },
          { answer: "Tove Enblom", isCorrect: !0 },
        ],
      },
      {
        id: 27,
        question:
          "Nyförvärvet Elsa Pelgander är den yngsta spelaren att erbjudas ett A-lagskontrakt i KIF Örebros damallsvenska A-lag. Hur gammal var Elsa när hon blev en del av laget?",
        answers: [
          { answer: "14 år gammal", isCorrect: !1 },
          { answer: "15 år gammal", isCorrect: !0 },
          { answer: "16 år gammal", isCorrect: !1 },
        ],
      },
      {
        id: 28,
        question:
          "2021 tog Anna Sandberg klivet upp till A-laget efter spel i klubbens F19-lag. Vilken är Annas moderklubb?",
        answers: [
          { answer: "Örebro SK Söder", isCorrect: !1 },
          { answer: "IFK Lindesberg", isCorrect: !0 },
          { answer: "IK Sturehov", isCorrect: !1 },
        ],
      },
      {
        id: 29,
        question:
          "Under vilka år spelade den tjeckiska fotbollsspelaren Pavlina Scasná för KIF Örebro?",
        answers: [
          { answer: "2004-2005", isCorrect: !1 },
          { answer: "2005-2006", isCorrect: !1 },
          { answer: "2006-2007", isCorrect: !0 },
        ],
      },
    ],
  },
];
