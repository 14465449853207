export const modalStylesCookies = {
  content: {
    width: "80%",
    top: "75%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
