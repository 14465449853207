import { ITeams } from "../models/ITeams";

export const TeamsAndGames: ITeams[] = [
  {
    id: 0,
    team: "AIK",
    link: "https://www.aikfotboll.se/aik-damer-biljetter",
  },
  {
    id: 1,
    team: "BP",
    link: "https://bp.ebiljett.nu/List/Events",
  },

  {
    id: 2,
    team: "Djurgården",
    link: "https://difbiljetter.se/obosdamallsvenskanbiljetter",
  },

  {
    id: 3,
    team: "Eskilstuna United",
    link: "https://eskilstunaunited.se/matchdag/biljetter/",
  },
  {
    id: 4,
    team: "Hammarby",
    link: "https://www.hammarbyfotboll.se/biljetter/kop-biljetter/",
  },
  {
    id: 5,
    team: "BK Häcken",
    link: "https://bkhacken.ebiljett.nu/List/Events",
  },
  {
    id: 6,
    team: "IFK Kalmar",
    link: "https://ifkkalmar.ebiljett.nu",
  },

  {
    id: 7,
    team: "Kristianstads DFF",
    link: "https://kdff.ebiljett.nu",
  },

  {
    id: 8,
    team: "Linköping FC",
    link: "https://lfc.ebiljett.nu",
  },
  {
    id: 9,
    team: "Piteå IF",
    link: "https://www.piteaifdff.se/sida/?ID=325895",
  },
  {
    id: 10,
    team: "FC Rosengård",
    link: "https://www.ticketmaster.se/artist/fc-rosengard-biljetter/961275",
  },

  {
    id: 11,
    team: "Umeå IK",
    link: "https://umeaik.ebiljett.nu",
  },
  {
    id: 12,
    team: "Vittsjö GIK",
    link: "https://www.svenskalag.se/vittsjogik/sida/13911/biljetter-priser-2021",
  },
  {
    id: 13,
    team: "KIF Örebro",
    link: "https://kiforebro.ebiljett.nu",
  },
];
