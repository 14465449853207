export const colors = {
  BackgroundBlue: "#172542",
  ButtonBlue: "#13253A",
  ResultBlue: "#1b3a55",
  ShieldBlue: "#122A42",
  TextBlue: "#161246",
  White: "#FFFFFF",
  Darkgrey: "#404040",
  CorrectGreen: "#6CE082",
  WronglyRed: "#DD4F4F",
};
